import React from 'react';
import { motion } from 'framer-motion';
import { FaShieldAlt, FaLightbulb, FaHandshake, FaUsers } from 'react-icons/fa';

const AboutUs = () => {
  const coreValues = [
    {
      icon: FaShieldAlt,
      title: "Accessibility",
      description: "Making GDPR compliance attainable for businesses of all sizes, ensuring no one is left behind."
    },
    {
      icon: FaLightbulb,
      title: "Innovation",
      description: "Pioneering cutting-edge solutions to simplify and enhance the compliance journey."
    },
    {
      icon: FaHandshake,
      title: "Integrity",
      description: "Upholding the highest standards of trust and reliability in every interaction."
    },
    {
      icon: FaUsers,
      title: "Empowerment",
      description: "Equipping organizations with the tools and knowledge to master their data protection practices."
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { staggerChildren: 0.3 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5, ease: "easeOut" }
    }
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      {/* Hero Section */}
      <section className="relative text-white">
        <div className="gradient-bg py-24 md:py-32">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <motion.h1 
              variants={itemVariants}
              className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-6"
            >
              About Dedalus.ie
            </motion.h1>
            <motion.p 
              variants={itemVariants}
              className="text-lg sm:text-xl lg:text-2xl mb-12 max-w-3xl mx-auto"
            >
              Your trusted partner in GDPR compliance and data protection
            </motion.p>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="py-24 bg-gray-100">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          {/* Our Story */}
          <motion.section variants={itemVariants} className="mb-16">
            <h2 className="text-3xl font-semibold mb-6">Our Story</h2>
            <p className="mb-4">
              Dedalus.ie was founded in Dublin, Ireland, to address the increasing demand for comprehensive GDPR compliance solutions in a data-driven business landscape. Recognizing the complexities and challenges organizations face in navigating data protection regulations, our founders assembled a team of seasoned data protection experts, legal professionals, and technology specialists.
            </p>
            <p className="mb-4">
              From the outset, our mission has been clear: to simplify GDPR compliance, making it accessible and manageable for businesses of all sizes. Starting with the development of intuitive self-assessment tools, Dedalus.ie has evolved into a full-spectrum compliance partner, offering expert consulting services, educational resources, and ongoing support to ensure our clients remain compliant and confident in their data protection practices.
            </p>
            <p>
              Our growth is driven by a commitment to excellence and a passion for empowering organizations to protect personal data effectively, fostering trust and integrity in their operations.
            </p>
          </motion.section>

          {/* Our Mission */}
          <motion.section variants={itemVariants} className="mb-16">
            <h2 className="text-3xl font-semibold mb-6">Our Mission</h2>
            <p className="mb-6">
              At Dedalus.ie, our mission is to make GDPR compliance effortless and accessible for organizations across Ireland and the EU. We strive to:
            </p>
            <ul className="list-disc pl-6 mb-6 space-y-2">
              <li><strong>Simplify Complex GDPR Requirements:</strong> Through intuitive tools and clear, actionable guidance, we break down intricate regulations into manageable steps.</li>
              <li><strong>Empower Businesses:</strong> Providing the knowledge and resources necessary for organizations to take control of their data protection practices.</li>
              <li><strong>Foster a Culture of Privacy:</strong> Encouraging and nurturing a strong commitment to data privacy across all levels of an organization.</li>
              <li><strong>Continuously Innovate:</strong> Leveraging the latest technologies to address evolving data protection challenges and enhance our service offerings.</li>
            </ul>
            <p>
              By achieving these objectives, we aim to contribute to a safer digital ecosystem where personal data is respected and protected, enabling businesses to build and maintain trust with their customers.
            </p>
          </motion.section>

          {/* Our Team */}
          <motion.section variants={itemVariants} className="mb-16">
            <h2 className="text-3xl font-semibold mb-6">Our Team</h2>
            <p className="mb-6">
              Behind Dedalus.ie is a diverse and dedicated team of professionals, each bringing a unique blend of expertise and passion to the table. Our collective knowledge ensures that we provide comprehensive and reliable GDPR compliance solutions tailored to your specific needs.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {/* Example Team Member */}
              {/* Replace with actual team members */}
              <motion.div 
                variants={itemVariants}
                className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-shadow duration-300"
              >
                <img 
                  src="https://via.placeholder.com/150" 
                  alt="Team Member" 
                  className="w-24 h-24 rounded-full mx-auto mb-4"
                />
                <h3 className="text-xl font-semibold text-center mb-2">Jane Doe</h3>
                <p className="text-center text-gray-600">Chief Data Protection Officer</p>
              </motion.div>
              {/* Add more team members as needed */}
            </div>
          </motion.section>

          {/* Our Approach */}
          <motion.section variants={itemVariants} className="mb-16">
            <h2 className="text-3xl font-semibold mb-6">Our Approach</h2>
            <p className="mb-6">
              At Dedalus.ie, we believe in a practical, technology-driven approach to GDPR compliance. Our methodology is built on three key pillars:
            </p>
            <ol className="list-decimal pl-6 space-y-4">
              <li>
                <strong>Simplification:</strong> We distill complex legal requirements into clear, actionable steps, making compliance manageable and straightforward for your organization.
              </li>
              <li>
                <strong>Automation:</strong> Leveraging cutting-edge technology, we automate compliance processes where possible, reducing manual effort and minimizing the risk of errors.
              </li>
              <li>
                <strong>Education:</strong> Providing continuous guidance and resources, we help organizations cultivate a robust culture of data protection and privacy.
              </li>
            </ol>
            <p className="mt-6">
              This comprehensive approach ensures that our solutions are not only effective but also sustainable, allowing your business to maintain compliance effortlessly as regulations evolve.
            </p>
          </motion.section>

          {/* Our Core Values */}
          <motion.section variants={itemVariants} className="mb-16">
            <h2 className="text-3xl font-semibold mb-6">Our Core Values</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {coreValues.map((value, index) => (
                <motion.div 
                  key={index} 
                  variants={itemVariants}
                  whileHover={{ scale: 1.05 }}
                  className="bg-white shadow-md rounded-lg p-6 flex flex-col items-center text-center hover:shadow-xl transition-shadow duration-300"
                >
                  <value.icon className="text-4xl text-blue-600 mb-4" />
                  <h3 className="text-xl font-semibold mb-2">{value.title}</h3>
                  <p className="text-gray-600">{value.description}</p>
                </motion.div>
              ))}
            </div>
          </motion.section>

          {/* Join Our Mission */}
          <motion.section variants={itemVariants}>
            <h2 className="text-3xl font-semibold mb-6">Join Our Mission</h2>
            <p className="mb-6">
              Whether you're a burgeoning startup or an established enterprise, Dedalus.ie is here to support your journey towards impeccable GDPR compliance. Our suite of free self-assessment tools, expert consulting services, and ongoing support are designed to fit seamlessly into your operations, empowering you to focus on what you do best.
            </p>
            <p className="mb-6">
              Explore our range of free tools, delve into our comprehensive resources, or reach out to our team to discover how we can tailor our solutions to meet your unique needs. Together, we can create a secure and trustworthy digital environment for your business and your customers.
            </p>
            <motion.a 
              href="/contact" 
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="inline-block bg-blue-600 text-white font-semibold py-3 px-6 rounded-lg shadow-md hover:bg-blue-700 transition-colors duration-300"
            >
              Get in Touch
            </motion.a>
          </motion.section>
        </div>
      </section>

      <style jsx>{`
        .gradient-bg {
          background: linear-gradient(135deg, #6ab7ff 0%, #1e3c72 100%);
        }
      `}</style>
    </motion.div>
  );
};

export default AboutUs;
