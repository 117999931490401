import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Download } from 'lucide-react';

const Blog = () => {
  const blogPosts = [
    {
      id: 1,
      title: 'GDPR Compliance Fact Sheet',
      excerpt: 'Learn the basics of GDPR compliance and how it affects your business operations.',
      date: 'October 10, 2024',
      route: '/gdpr-compliance-fact-sheet',
      pdfUrl: '/pdfs/gdpr-compliance-fact-sheet.pdf',
    },
    {
      id: 2,
      title: 'Data Protection Best Practices',
      excerpt: 'Implement these best practices to enhance your data protection strategies.',
      date: 'November 5, 2024',
      route: '/data-protection-best-practices',
      pdfUrl: '/pdfs/DataProtection_WhatsItAllAbout_Apr22.pdf',
    },
    {
      id: 3,
      title: 'International Data Transfers Explained',
      excerpt: 'Navigate the complexities of international data transfers with our comprehensive guide.',
      date: 'December 15, 2024',
      route: '/international-data-transfers-explained',
      pdfUrl: '/pdfs/international-data-transfers-explained.pdf',
    },
  ];

  return (
    <motion.section
      className="py-24 bg-gray-100"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto px-6">
        <h1 className="text-4xl font-bold text-center mb-12">Blog</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {blogPosts.map((post) => (
            <div key={post.id} className="bg-white p-8 rounded-lg shadow-md">
              <a href={post.pdfUrl} target="_blank" rel="noopener noreferrer">
                <h2 className="text-2xl font-semibold mb-4">{post.title}</h2>
              </a>
              <p className="text-gray-600 mb-4">{post.date}</p>
              <p className="mb-6">{post.excerpt}</p>
              <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 mb-4">
                <a
                  href={post.pdfUrl}
                  download
                  className="inline-flex items-center justify-center text-green-600 hover:text-green-800 font-medium text-sm transition duration-200 ease-in-out"
                >
                  <Download className="w-5 h-5" />
                </a>
              </div>
              <Link
                to={post.route}
                state={{ pdfUrl: post.pdfUrl }}
                className="inline-block text-blue-600 hover:text-blue-800 font-medium text-sm transition duration-200 ease-in-out"
              >
                Read More →
              </Link>
            </div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default Blog;
