import React, { useEffect } from 'react';

const DPIAScreening = () => {
  useEffect(() => {
    // Hide the header when this component mounts
    document.querySelector('header').style.display = 'none';

    // Show the header again when the component unmounts
    return () => {
      document.querySelector('header').style.display = 'block';
    };
  }, []);

  return (
    <div className="fixed inset-0 w-full h-full">
      <iframe
        src="https://dpia-screening.vercel.app/"
        title="DPIA Screening Tool"
        className="w-full h-full border-0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default DPIAScreening;