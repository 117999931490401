// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
} from 'react-icons/fa';

const Footer = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle newsletter signup
    console.log('Newsletter signup submitted');
  };

  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="container mx-auto px-6">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h3 className="text-2xl font-bold mb-4">Dedalus.ie</h3>
            <p className="mb-4">
              Simplifying GDPR compliance for businesses of all sizes.
            </p>
            <div className="flex space-x-4">
              <button
                className="hover:text-blue-400 transition-colors duration-300"
                aria-label="Facebook"
                onClick={() => window.open('https://facebook.com/dedalus', '_blank')}
              >
                <FaFacebookF />
              </button>
              <button
                className="hover:text-blue-400 transition-colors duration-300"
                aria-label="Twitter"
                onClick={() => window.open('https://twitter.com/dedalus', '_blank')}
              >
                <FaTwitter />
              </button>
              <button
                className="hover:text-blue-400 transition-colors duration-300"
                aria-label="LinkedIn"
                onClick={() => window.open('https://linkedin.com/company/dedalus', '_blank')}
              >
                <FaLinkedinIn />
              </button>
              <button
                className="hover:text-pink-400 transition-colors duration-300"
                aria-label="Instagram"
                onClick={() => window.open('https://instagram.com/dedalus', '_blank')}
              >
                <FaInstagram />
              </button>
            </div>
          </div>
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h3 className="text-xl font-bold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              {[
                'Home',
                'Services',
                'About',
                'Pricing',
                'Testimonials',
                'Blog',
                'FAQ',
                'Contact',
              ].map((item) => (
                <li key={item}>
                  <Link
                    to={item === 'Home' ? '/' : `/${item.toLowerCase()}`}
                    className="hover:text-blue-300 transition-colors duration-300"
                  >
                    {item}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h3 className="text-xl font-bold mb-4">Contact Us</h3>
            <p>
              Email:{' '}
              <a
                href="mailto:info@dedalus.ie"
                className="hover:text-blue-300 transition-colors duration-300"
              >
                info@dedalus.ie
              </a>
            </p>
            <p>
              Phone:{' '}
              <a
                href="tel:+35319020020"
                className="hover:text-blue-300 transition-colors duration-300"
              >
                01 902 0020
              </a>
            </p>
            <p>Address: 123 Tech Street, Dublin, Ireland</p>
          </div>
          <div className="w-full md:w-1/4">
            <h3 className="text-xl font-bold mb-4">Newsletter</h3>
            <form onSubmit={handleSubmit} className="flex flex-col">
              <input
                type="email"
                placeholder="Your email"
                className="mb-2 p-2 rounded text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded transition-colors duration-300"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700 text-center">
          <p>
            &copy; {new Date().getFullYear()} Dedalus.ie - All Rights Reserved |{' '}
            <Link
              to="/privacy-policy"
              className="hover:text-blue-300 transition-colors duration-300"
            >
              Privacy Policy
            </Link>{' '}
            |{' '}
            <Link
              to="/terms-of-service"
              className="hover:text-blue-300 transition-colors duration-300"
            >
              Terms of Service
            </Link>{' '}
            |{' '}
            <Link
              to="/cookies-notice"
              className="hover:text-blue-300 transition-colors duration-300"
            >
              Cookies Notice
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
