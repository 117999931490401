// src/PricingPage.js 

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {
  FaShieldAlt,
  FaClipboardCheck,
  FaBalanceScale,
  FaSearch,
  FaFileAlt,
  FaProjectDiagram,
  FaGraduationCap,
  FaUserTie,
  FaLock,
  FaCog,
  FaChartLine,
  FaUsers,
  FaBuilding,
  FaUserShield,
  FaBug,
  FaTruckLoading,
  FaGlobe,
  FaRobot,
  FaChartBar,
  FaFileContract,
  FaMapMarkedAlt,
  FaChalkboardTeacher,
  FaCertificate,
  FaTimes, // Close icon for modal
} from 'react-icons/fa';

// Reusable Card Component for Free Tools
const FreeToolCard = ({ icon: Icon, title, description, link, onClick }) => (
  <div
    className="bg-white p-6 rounded-lg shadow-md text-center cursor-pointer hover:shadow-xl transition-shadow duration-300"
    onClick={onClick}
  >
    <Icon className="text-4xl text-blue-600 mb-4 mx-auto" />
    <h3 className="text-xl font-semibold mb-3">{title}</h3>
    <p className="mb-4 text-sm">{description}</p>
    {link.startsWith('http') ? (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-600 hover:text-blue-800 font-semibold text-sm"
        onClick={(e) => e.stopPropagation()} // Prevent modal from opening when clicking the link
      >
        Start Assessment →
      </a>
    ) : (
      <Link
        to={link}
        className="text-blue-600 hover:text-blue-800 font-semibold text-sm"
        onClick={(e) => e.stopPropagation()} // Prevent modal from opening when clicking the link
      >
        Start Assessment →
      </Link>
    )}
  </div>
);

// Reusable Card Component for Expert Services
const ExpertServiceCard = ({ icon: Icon, name, description, pricing, onClick }) => (
  <div
    className="bg-white p-8 rounded-lg shadow-md cursor-pointer hover:shadow-xl transition-shadow duration-300"
    onClick={onClick}
  >
    <Icon className="text-4xl text-blue-600 mb-4" />
    <h3 className="text-2xl font-semibold mb-4">{name}</h3>
    <p className="mb-4">{description}</p>
    <ul className="mb-6">
      {pricing.map((priceItem, index) => (
        <li key={index} className="mb-2">
          <span className="font-semibold">{priceItem.name}:</span> {priceItem.price}
          <br />
          <span className="text-sm text-gray-600">{priceItem.details}</span>
        </li>
      ))}
    </ul>
    <Link
      to="/contact"
      className="bg-blue-600 text-white font-semibold py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
      onClick={(e) => e.stopPropagation()} // Prevent modal from opening when clicking the link
    >
      Request a Quote
    </Link>
  </div>
);

// Reusable Card Component for Subscriptions and Packages
const PricingCard = ({ name, price, features, link, isSubscription, icon: Icon, onClick }) => (
  <div
    className="bg-white p-8 rounded-lg shadow-md cursor-pointer hover:shadow-xl transition-shadow duration-300"
    onClick={onClick}
  >
    {Icon && <Icon className="text-4xl text-blue-600 mb-4 mx-auto" />}
    <h3 className="text-2xl font-semibold mb-4 text-center">{name}</h3>
    <p className="text-3xl font-bold mb-6 text-center">{price}</p>
    <ul className="mb-8">
      {features.map((feature, index) => (
        <li key={index} className="mb-2 flex items-start">
          <svg
            className="h-6 w-6 text-green-500 mr-2 flex-shrink-0"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
          <span>{feature}</span>
        </li>
      ))}
    </ul>
    <Link
      to={link}
      className="bg-blue-600 text-white font-semibold py-2 px-4 rounded hover:bg-blue-700 transition duration-300 block text-center"
      onClick={(e) => e.stopPropagation()} // Prevent modal from opening when clicking the link
    >
      {isSubscription ? 'Subscribe Now' : 'Get Started'}
    </Link>
  </div>
);

// Modal Component
const Modal = ({ isVisible, onClose, title, children }) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <>
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-50 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
          />
          <motion.div
            className="fixed inset-0 flex items-center justify-center z-50 p-4"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
          >
            <div className="bg-white rounded-lg shadow-lg max-w-3xl w-full relative">
              <button
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                onClick={onClose}
              >
                <FaTimes size={24} />
              </button>
              <div className="p-6">
                <h2 className="text-2xl font-bold mb-4">{title}</h2>
                <div className="prose max-w-none">
                  {children}
                </div>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

const PricingPage = () => {
  const [modalContent, setModalContent] = useState(null);

  const openModal = (content) => setModalContent(content);
  const closeModal = () => setModalContent(null);

  const freeTools = [
    {
      icon: FaShieldAlt,
      title: 'AI Compliance Checker',
      description: 'Self-assess your AI applications for GDPR compliance.',
      link: 'https://ai-checklist.vercel.app/',
      details: `
### AI Compliance Checker

**Description:**
Our AI Compliance Checker allows you to self-assess your AI applications to ensure they meet GDPR compliance standards. Identify potential privacy risks and receive recommendations for improvement.

**Features:**
- Automated compliance checks tailored for AI systems.
- Comprehensive risk analysis reports.
- Actionable insights to enhance data protection measures.

**Benefits:**
- **Time and Resource Saving:** Save time and resources by automating compliance assessments.
- **High Data Protection Standards:** Ensure your AI applications adhere to the highest data protection standards.
- **Risk Mitigation:** Mitigate risks associated with non-compliance.
      `,
    },
    {
      icon: FaClipboardCheck,
      title: 'DPIA Screening Assessment',
      description: 'Determine if you need to conduct a full DPIA.',
      link: '/dpia-screening',
      details: `
### DPIA Screening Assessment

**Description:**
Use our DPIA Screening Assessment tool to evaluate whether your data processing activities require a full Data Protection Impact Assessment (DPIA).

**Features:**
- Quick and easy assessment process.
- Criteria-based evaluation to determine DPIA necessity.
- Recommendations based on assessment results.

**Benefits:**
- **Streamlined Compliance:** Streamline your compliance process by identifying when a DPIA is necessary.
- **Workload Reduction:** Reduce unnecessary workload by avoiding full DPIAs when not required.
- **GDPR Compliance:** Ensure compliance with GDPR requirements.
      `,
    },
    {
      icon: FaBalanceScale,
      title: 'Legitimate Interest Tool',
      description: 'Evaluate your lawful basis for data processing.',
      link: 'https://legitimate-interest-assessment.vercel.app/',
      details: `
### Legitimate Interest Tool

**Description:**
Assess whether your data processing activities are based on legitimate interests under GDPR. This tool helps you evaluate and document your lawful basis for processing personal data.

**Features:**
- Comprehensive evaluation of legitimate interest claims.
- Documentation templates for compliance.
- Recommendations for strengthening your data processing practices.

**Benefits:**
- **Legal Compliance:** Ensure that your data processing activities are legally compliant.
- **Transparency and Accountability:** Enhance transparency and accountability in your data handling.
- **Simplified Justification:** Simplify the process of justifying legitimate interests.
      `,
    },
    {
      icon: FaSearch,
      title: 'DPIA Assessment',
      description: 'Conduct a comprehensive Data Protection Impact Assessment.',
      link: '/dpia-assessment',
      details: `
### DPIA Assessment

**Description:**
Perform a detailed Data Protection Impact Assessment (DPIA) to identify and mitigate privacy risks associated with your data processing activities.

**Features:**
- Step-by-step DPIA guidance.
- Risk identification and mitigation strategies.
- Comprehensive reporting tools.

**Benefits:**
- **Proactive Risk Management:** Proactively manage and reduce data privacy risks.
- **GDPR Compliance:** Ensure compliance with GDPR's DPIA requirements.
- **Stakeholder Trust:** Enhance trust with stakeholders through thorough risk assessments.
      `,
    },
  ];

  const expertServices = [
    {
      icon: FaFileAlt,
      name: 'DPIA Consulting',
      description: 'Expert guidance on conducting Data Protection Impact Assessments for your projects.',
      pricing: [
        { name: 'Basic Package', price: '€2,000', details: 'Assessment of one data processing activity' },
        { name: 'Standard Package', price: '€5,000', details: 'Assessments of up to three data processing activities' },
        { name: 'Premium Package', price: '€10,000', details: 'Unlimited data processing activity assessments' },
      ],
      details: `
### What You Get:
- **Comprehensive Risk Assessment:** Our experts conduct a thorough analysis of your data processing activities to identify potential privacy risks.
- **GDPR Compliance Evaluation:** Ensure that your data processing aligns with GDPR requirements, minimising the risk of non-compliance penalties.
- **Actionable Recommendations:** Receive detailed strategies and solutions to mitigate identified risks and enhance data protection measures.
- **Stakeholder Collaboration:** Work closely with your team to understand specific project needs and tailor assessments accordingly.
- **Documentation Support:** Assistance in documenting DPIA findings and integrating them into your overall data protection framework.

### Benefits:
- **Proactive Risk Management:** Identify and address data privacy risks before they become critical issues.
- **Enhanced Trust:** Build credibility with clients and stakeholders through demonstrated compliance.
- **Efficient Compliance Processes:** Streamline your compliance efforts, reducing the burden on internal resources.
      `,
    },
    {
      icon: FaProjectDiagram,
      name: 'Privacy Programme Development',
      description: "Comprehensive privacy programme creation tailored to your organisation's needs.",
      pricing: [
        { name: 'Basic', price: '€3,000', details: 'Initial assessment and basic framework' },
        { name: 'Standard', price: '€7,000', details: 'Customised programme and implementation support' },
        { name: 'Premium', price: '€15,000', details: 'Full-scale development and year-long support' },
      ],
      details: `
### What You Get:
- **Initial Privacy Assessment:** Evaluate your current privacy practices to identify gaps and areas for improvement.
- **Customised Privacy Framework:** Development of a tailored privacy programme that aligns with your business objectives and regulatory requirements.
- **Policy and Procedure Development:** Creation of comprehensive privacy policies, data handling procedures, and governance structures.
- **Implementation Support:** Hands-on assistance in deploying the privacy programme across your organisation, including training and change management.
- **Ongoing Maintenance:** For Premium packages, continuous support to adapt and refine your privacy programme as your business evolves.

### Benefits:
- **Robust Privacy Infrastructure:** Establish a strong foundation to support business growth and data protection.
- **Consistent Compliance:** Ensure uniform data handling practices across all departments.
- **Enhanced Accountability:** Promote organisational transparency and responsibility in data protection.
      `,
    },
    {
      icon: FaGraduationCap,
      name: 'Data Protection Training',
      description: 'Customised training courses to ensure your team is up-to-date with GDPR requirements.',
      pricing: [
        { name: 'Online Course', price: '€500 per session', details: 'Self-paced modules with certification' },
        { name: 'On-Site Training', price: '€2,500 per day', details: 'Customised training at your location' },
      ],
      details: `
### What You Get:
- **Comprehensive Curriculum:** Courses covering all aspects of GDPR, data protection principles, and best practices.
- **Flexible Learning Formats:** Choose between self-paced online modules or interactive on-site training sessions tailored to your team's needs.
- **Certified Instructors:** Learn from experienced professionals with extensive knowledge in data protection and compliance.
- **Practical Exercises:** Engage in real-world scenarios and case studies to apply learning effectively.
- **Certification:** Receive official certification upon completion, demonstrating your team's proficiency in data protection.

### Benefits:
- **Empowered Employees:** Equip your team with the knowledge to handle personal data responsibly.
- **Culture of Compliance:** Foster a proactive approach to data privacy within your organisation.
- **Risk Reduction:** Minimise the likelihood of data breaches and non-compliance through informed and trained staff.
      `,
    },
    {
      icon: FaUserTie,
      name: 'Assessment Review',
      description: 'Professional review of your self-assessment results with detailed recommendations.',
      pricing: [
        { name: 'Single Review', price: '€1,000', details: 'Review of one assessment' },
        { name: 'Bulk Review', price: '€4,000', details: 'Review of up to five assessments' },
      ],
      details: `
### What You Get:
- **Thorough Evaluation:** Detailed analysis of your self-assessment to identify strengths and weaknesses in your data protection practices.
- **Expert Feedback:** Insights and recommendations from seasoned data protection professionals to enhance your compliance efforts.
- **Action Plan Development:** Creation of a strategic roadmap to address identified gaps and improve overall data protection measures.
- **Compliance Verification:** Ensure that your assessments align with GDPR and other relevant regulations.

### Benefits:
- **Objective Perspective:** Gain an objective perspective on your data protection status.
- **Targeted Improvements:** Implement targeted improvements to bolster compliance.
- **Enhanced Accuracy:** Enhance the accuracy and effectiveness of your self-assessments.
      `,
    },
    {
      icon: FaLock,
      name: 'Compliance Audit',
      description: 'Thorough audit of your current practices to identify and address compliance gaps.',
      pricing: [
        { name: 'Small Business Audit', price: '€3,000', details: 'For businesses with up to 50 employees' },
        { name: 'Enterprise Audit', price: '€10,000', details: 'For large organisations with over 50 employees' },
      ],
      details: `
### What You Get:
- **Comprehensive Audit Process:** Examination of your data protection policies, procedures, and technical measures to assess compliance.
- **Gap Analysis:** Identification of non-compliance areas and potential risks within your current practices.
- **Detailed Reporting:** Receive a comprehensive audit report outlining findings, risk levels, and prioritised recommendations.
- **Remediation Support:** Guidance and assistance in implementing necessary changes to achieve full compliance.
- **Follow-Up Review:** Optional post-audit review to ensure that remediation actions have been effectively implemented.

### Benefits:
- **Full Compliance:** Achieve and maintain full compliance with GDPR and other data protection regulations.
- **Risk Mitigation:** Mitigate risks associated with non-compliance, including fines and reputational damage.
- **Enhanced Framework:** Enhance your organisation’s data protection framework through expert-led evaluations and improvements.
      `,
    },
    {
      icon: FaCog,
      name: 'Ongoing Compliance Support',
      description: 'Continuous guidance and support to maintain GDPR compliance as your business evolves.',
      pricing: [
        { name: 'Basic', price: '€500/month', details: 'Monthly check-ins and email support' },
        { name: 'Standard', price: '€1,200/month', details: 'Bi-weekly check-ins and priority support' },
        { name: 'Premium', price: '€2,500/month', details: 'Weekly consultations and dedicated manager' },
      ],
      details: `
### What You Get:
- **Regular Compliance Check-Ins:** Scheduled consultations to review your current compliance status and address any issues.
- **Priority Support:** Faster response times and dedicated support channels depending on your subscription level.
- **Dedicated Compliance Manager:** For Premium subscribers, a dedicated expert to oversee all aspects of your compliance program.
- **Regulatory Updates:** Stay informed about the latest changes in data protection laws and how they impact your business.
- **Continuous Improvement:** Ongoing assessments and recommendations to enhance your data protection strategies.

### Benefits:
- **Sustained Compliance:** Ensure sustained compliance with evolving data protection regulations.
- **Reduced Burden:** Reduce the administrative burden on your internal teams with expert support.
- **Swift Resolution:** Quickly address and resolve compliance challenges as your business grows and changes.
      `,
    },
    {
      icon: FaChartLine,
      name: 'Privacy by Design Consulting',
      description: 'Integration of privacy principles into your applications and IT systems.',
      pricing: [
        { name: 'Standard Project', price: 'Starting at €8,000', details: 'For one application or IT system' },
        { name: 'Complex Project', price: 'Starting at €20,000', details: 'For multiple applications or systems' },
      ],
      details: `
### What You Get:
- **Privacy Integration Strategy:** Develop a comprehensive plan to embed privacy considerations into the design and development of your applications and IT systems.
- **Technical Implementation:** Assistance with implementing privacy-enhancing technologies and secure coding practices.
- **Privacy Impact Assessments:** Conduct assessments to evaluate the privacy implications of your systems and processes.
- **Collaboration with Development Teams:** Work closely with your IT and development teams to ensure privacy principles are upheld throughout the project lifecycle.
- **Scalable Solutions:** Tailored approaches to accommodate single or multiple applications, ensuring consistency and efficiency.

### Benefits:
- **Built-In Privacy:** Build privacy into your products from the ground up, reducing the need for costly retrofits.
- **User Trust:** Enhance user trust by demonstrating a commitment to data protection.
- **Streamlined Compliance:** Streamline compliance with GDPR’s accountability and data protection by design requirements.
      `,
    },
    {
      icon: FaUserShield,
      name: 'DPO as a Service',
      description: 'Provide qualified Data Protection Officers on a part-time or full-time basis.',
      pricing: [
        { name: 'Part-Time DPO', price: '€1,500 - €3,000/month', details: 'Qualified DPO tailored to company size' },
        { name: 'Full-Time DPO', price: '€4,000 - €5,000/month', details: 'Dedicated full-time Data Protection Officer' },
      ],
      details: `
### What You Get:
- **Experienced Data Protection Officers:** Access to certified DPOs with extensive knowledge in GDPR and data protection.
- **Regulatory Compliance Oversight:** Continuous monitoring and management of your data protection strategies to ensure ongoing compliance.
- **Policy Development and Implementation:** Assistance in creating and enforcing data protection policies and procedures.
- **Liaison with Authorities:** Serve as the primary contact for data protection authorities and handle compliance-related communications.
- **Risk Management:** Identify and mitigate data protection risks through regular assessments and audits.

### Benefits:
- **Professional Leadership:** Benefit from professional data protection leadership without the cost of hiring a full-time officer.
- **Expert Oversight:** Ensure expert oversight of your data protection practices and regulatory compliance.
- **Resource Efficiency:** Free up internal resources to focus on core business activities while maintaining robust data protection standards.
      `,
    },
    {
      icon: FaBug,
      name: 'Incident Response Management',
      description: 'Develop incident response plans and provide support during data breaches.',
      pricing: [
        { name: 'Incident Response Planning', price: '€3,000 (fixed fee)', details: 'Develop and implement incident response plans' },
        { name: 'Data Breach Management', price: '€200/hour', details: 'Support during data breaches and post-incident reviews' },
      ],
      details: `
### What You Get:
- **Customised Incident Response Plans:** Develop tailored plans that outline the steps to take in the event of a data breach, ensuring swift and effective action.
- **Breach Detection and Notification:** Implement systems for early detection of breaches and manage the notification process to regulators and affected parties.
- **Containment and Remediation:** Strategies to contain the breach, mitigate damage, and restore normal operations.
- **Post-Incident Analysis:** Conduct thorough investigations to understand the breach’s cause and prevent future occurrences.
- **Crisis Communication Support:** Guidance on managing communications internally and externally to maintain trust and transparency.

### Benefits:
- **Minimised Impact:** Minimise the impact of data breaches through prepared and efficient response strategies.
- **Regulatory Compliance:** Comply with GDPR’s breach notification requirements, avoiding hefty fines.
- **Reputation Protection:** Protect your organisation’s reputation by demonstrating accountability and responsiveness.
      `,
    },
    {
      icon: FaTruckLoading,
      name: 'Vendor Risk Management',
      description: 'Assess and manage GDPR compliance of third-party vendors and partners.',
      pricing: [
        { name: 'Third-Party Compliance Assessment', price: '€500 per vendor', details: 'Assess and manage GDPR compliance of vendors' },
      ],
      details: `
### What You Get:
- **Comprehensive Vendor Assessments:** Evaluate the data protection practices of your third-party vendors to ensure they meet GDPR standards.
- **Risk Scoring:** Assign risk levels to vendors based on their compliance status and data handling practices.
- **Contractual Recommendations:** Guidance on incorporating data protection clauses into vendor contracts to safeguard your data.
- **Ongoing Monitoring:** Regular reviews of vendor compliance to address any changes or emerging risks.
- **Vendor Compliance Reports:** Detailed reports summarising each vendor’s compliance status and recommendations for improvement.

### Benefits:
- **High Standards:** Ensure that your vendors uphold the same high standards of data protection as your organisation.
- **Risk Reduction:** Reduce the risk of data breaches and non-compliance through diligent vendor management.
- **Enhanced Framework:** Strengthen your overall data protection framework by integrating compliant third-party partners.
      `,
    },
    {
      icon: FaGlobe,
      name: 'International Compliance',
      description: 'Extend services to include compliance with CCPA, HIPAA, etc., for multinational clients.',
      pricing: [
        { name: 'Cross-Jurisdictional Compliance', price: 'Starting at €10,000/project', details: 'Compliance with CCPA, HIPAA, etc., tailored for multinational clients' },
      ],
      details: `
### What You Get:
- **Multi-Region Compliance Strategies:** Develop tailored compliance strategies that address the specific requirements of various jurisdictions, including CCPA, HIPAA, and others.
- **Regulatory Gap Analysis:** Identify and address differences between GDPR and other regional data protection laws to ensure comprehensive compliance.
- **Data Transfer Solutions:** Implement lawful data transfer mechanisms, such as Standard Contractual Clauses (SCCs) and Binding Corporate Rules (BCRs), to facilitate cross-border data flows.
- **Localised Policy Development:** Create data protection policies and procedures that comply with the specific regulations of each target region.
- **Global Compliance Monitoring:** Ongoing monitoring and updates to your compliance strategies as international regulations evolve.

### Benefits:
- **Seamless Operation:** Seamlessly operate across multiple jurisdictions without compliance concerns.
- **Risk Mitigation:** Mitigate risks associated with international data transfers and regulatory discrepancies.
- **Global Reputation:** Enhance your organisation’s global reputation by adhering to diverse data protection standards.
      `,
    },
    {
      icon: FaRobot,
      name: 'Automated Compliance Tools',
      description: 'Leverage AI-powered tools for continuous compliance monitoring and real-time updates.',
      pricing: [
        { name: 'AI-Powered Compliance Monitoring', price: '€1,000/month', details: 'Continuous monitoring using AI tools' },
      ],
      details: `
### What You Get:
- **Real-Time Compliance Tracking:** Utilise AI-driven tools to monitor your data processing activities continuously, ensuring ongoing compliance.
- **Automated Alerts:** Receive instant notifications of any compliance deviations or potential breaches, enabling swift action.
- **Data Processing Mapping:** Automatically map and categorise data flows within your organisation to maintain an up-to-date data inventory.
- **Regulatory Updates Integration:** Stay informed with real-time updates on changes in data protection laws and adjust your compliance strategies accordingly.
- **Comprehensive Dashboards:** Access intuitive dashboards that provide a clear overview of your compliance status and key metrics.

### Benefits:
- **Continuous Oversight:** Maintain continuous oversight of your compliance posture without manual intervention.
- **Swift Identification:** Quickly identify and address compliance issues before they escalate.
- **Resource Efficiency:** Save time and resources by automating routine compliance monitoring tasks.
      `,
    },
    {
      icon: FaChartBar,
      name: 'Customised Reporting',
      description: 'Advanced reporting tools providing detailed insights into your compliance status.',
      pricing: [
        { name: 'Advanced Compliance Dashboards', price: '€800/setup + €300/month', details: 'Detailed insights and analytics on compliance metrics' },
      ],
      details: `
### What You Get:
- **Tailored Reporting Solutions:** Develop customised reports that align with your specific compliance needs and business objectives.
- **Detailed Analytics:** Gain deep insights into your data protection practices through comprehensive analytics and visualisations.
- **Automated Report Generation:** Schedule regular reports to be generated and delivered automatically, ensuring timely access to compliance information.
- **Interactive Dashboards:** Utilise interactive tools to explore data, identify trends, and make informed decisions based on real-time information.
- **Compliance Scorecards:** Track your compliance performance with easy-to-understand scorecards and benchmarks.

### Benefits:
- **Data-Driven Decisions:** Make data-driven decisions to enhance your compliance strategies.
- **Stakeholder Demonstration:** Easily demonstrate compliance to stakeholders and regulatory bodies with detailed reports.
- **Progress Monitoring:** Monitor progress and identify areas for improvement through actionable insights.
      `,
    },
    {
      icon: FaFileContract,
      name: 'Privacy Documentation Services',
      description: 'Assist in drafting, reviewing, and updating privacy policies and agreements.',
      pricing: [
        { name: 'Privacy Policy Drafting & Review', price: '€250 per document', details: 'Drafting and updating privacy policies and agreements' },
      ],
      details: `
### What You Get:
- **Comprehensive Document Drafting:** Create detailed privacy policies, data processing agreements, and other essential documentation tailored to your business needs.
- **Legal Compliance:** Ensure that all documents comply with GDPR and other relevant data protection laws.
- **Regular Updates:** Keep your documentation up-to-date with the latest regulatory changes and organisational modifications.
- **Customisation:** Tailor documents to reflect your specific data processing activities, business operations, and risk management strategies.
- **Review and Feedback:** Receive thorough reviews and feedback from our legal experts to refine and perfect your privacy documents.

### Benefits:
- **Legal Compliance:** Maintain legally compliant and transparent data protection documentation.
- **Risk Reduction:** Reduce the risk of non-compliance through professionally drafted and reviewed documents.
- **Enhanced Trust:** Enhance trust with customers and partners by clearly communicating your data protection practices.
      `,
    },
    {
      icon: FaMapMarkedAlt,
      name: 'Data Mapping Services',
      description: 'Help map data flows and maintain comprehensive data inventories.',
      pricing: [
        { name: 'Data Flow Mapping & Inventory', price: '€4,000/project', details: 'Map data flows and maintain data inventories' },
      ],
      details: `
### What You Get:
- **Comprehensive Data Flow Mapping:** Visualise and document how data moves within your organisation, including collection, storage, processing, and sharing.
- **Data Inventory Maintenance:** Keep an up-to-date inventory of all personal data held, ensuring transparency and accountability.
- **Risk Identification:** Identify potential vulnerabilities and risks associated with data flows to prioritise data protection efforts.
- **Integration with Compliance Frameworks:** Align your data mapping with GDPR requirements and other relevant regulations.
- **Automated Tools Integration:** Utilise advanced tools to streamline and automate the data mapping process for ongoing accuracy.

### Benefits:
- **Clear Data Landscape:** Gain a clear understanding of your data landscape to support effective data protection strategies.
- **GDPR Compliance:** Facilitate compliance with GDPR’s accountability and data mapping requirements.
- **Risk Mitigation:** Identify and mitigate data protection risks through detailed data flow insights.
      `,
    },
    {
      icon: FaChalkboardTeacher,
      name: 'Employee Awareness Programs',
      description: 'Develop ongoing education programs on data protection and GDPR best practices.',
      pricing: [
        { name: 'Ongoing Employee Training Programs', price: '€1,000/program', details: 'Continuous education on data protection and GDPR best practices' },
      ],
      details: `
### What You Get:
- **Customised Training Modules:** Develop training programs tailored to different roles and departments within your organisation.
- **Interactive Workshops:** Engage employees through workshops, seminars, and hands-on activities to reinforce data protection principles.
- **Continuous Learning Resources:** Provide access to ongoing learning materials, including webinars, e-learning courses, and newsletters.
- **Assessment and Feedback:** Evaluate employee understanding through quizzes and feedback sessions to ensure effective knowledge retention.
- **Culture Building:** Foster a culture of data protection and privacy awareness across all levels of your organisation.

### Benefits:
- **Enhanced Understanding:** Enhance employee understanding and responsibility regarding data protection.
- **Error Reduction:** Reduce the likelihood of data breaches caused by human error.
- **Proactive Privacy Culture:** Promote a proactive approach to data privacy within your organisation.
      `,
    },
    {
        
      icon: FaCertificate,
      name: 'Compliance Certification Programs',
      description: "Offer certification services validating your organisation's compliance efforts.",
      pricing: [
        { name: 'GDPR Compliance Certification', price: '€5,000/company', details: 'Certification validating compliance efforts' },
      ],
      details: `
### What You Get:
- **Rigorous Assessment Process:** Comprehensive evaluation of your data protection practices against GDPR standards.
- **Certification Awarded:** Upon successful assessment, receive an official certification that validates your compliance efforts.
- **Detailed Feedback:** Receive a thorough report outlining your strengths and areas for improvement in data protection.
- **Marketing Advantage:** Use your certification to enhance your reputation and build trust with clients and partners.
- **Renewal and Continuous Compliance:** Support for maintaining and renewing your certification through ongoing compliance monitoring and assessments.

### Benefits:
- **Stakeholder Trust:** Demonstrate your commitment to data protection and regulatory compliance to stakeholders.
- **Competitive Edge:** Gain a competitive edge in the marketplace by showcasing certified compliance.
- **Continuous Improvement:** Ensure continuous improvement and adherence to best practices in data protection.
      `,
    },
  ];

  const subscriptions = [
    {
      name: 'Basic Subscription',
      price: '€300/month',
      features: [
        'Access to all free self-assessment tools',
        'Quarterly compliance check-ins',
        'Access to exclusive webinars and resources',
      ],
      details: `
### What You Get:
- **Self-Assessment Tools:** Utilise a suite of tools to evaluate your current compliance status and identify areas for improvement.
- **Quarterly Check-Ins:** Regular consultations with our experts to review your compliance efforts and address any emerging issues.
- **Exclusive Webinars and Resources:** Gain access to specialised webinars, whitepapers, and resources to stay informed about the latest in data protection.

### Benefits:
- **Foundational Compliance:** Maintain a foundational level of compliance with ongoing support.
- **Stay Updated:** Keep abreast of the latest data protection trends and best practices.
- **Easy Monitoring:** Easily monitor and manage your compliance status with regular assessments.
      `,
    },
    {
      name: 'Standard Subscription',
      price: '€750/month',
      features: [
        'Everything in Basic Subscription',
        'Monthly compliance check-ins',
        'Priority support (email & phone)',
        'Annual GDPR compliance audit discount (10%)',
      ],
      details: `
### What You Get:
- **Enhanced Support:** Benefit from monthly compliance consultations to proactively manage and improve your data protection strategies.
- **Priority Support:** Receive faster response times and dedicated support channels for any compliance-related inquiries.
- **Audit Discounts:** Enjoy a 10% discount on our annual GDPR compliance audits, ensuring thorough evaluations at a reduced cost.

### Benefits:
- **Strengthened Compliance:** Gain more frequent and in-depth support to strengthen your compliance efforts.
- **Swift Issue Resolution:** Resolve compliance issues swiftly with priority access to our experts.
- **Cost Savings:** Save on comprehensive audits that provide detailed insights into your compliance status.
      `,
    },
    {
      name: 'Premium Subscription',
      price: '€1,500/month',
      features: [
        'Everything in Standard Subscription',
        'Bi-weekly compliance consultations',
        'Dedicated account manager',
        'Comprehensive annual GDPR compliance audit',
        'Customised compliance reports and dashboards',
      ],
      details: `
### What You Get:
- **Bi-Weekly Consultations:** Receive regular, in-depth consultations to continuously refine and optimise your compliance strategies.
- **Dedicated Account Manager:** Enjoy personalised support and guidance from a dedicated expert who understands your unique compliance needs.
- **Comprehensive Annual Audit:** Conduct a full-scale GDPR compliance audit annually, ensuring your practices are thoroughly evaluated and enhanced.
- **Customised Reporting Tools:** Access tailored reports and dashboards that provide detailed insights into your compliance metrics and progress.

### Benefits:
- **Top-Tier Compliance:** Achieve a high level of compliance with ongoing, personalised support.
- **Expert Guidance:** Benefit from dedicated expertise that aligns with your business objectives.
- **Informed Decisions:** Utilise advanced reporting tools to make informed decisions and track compliance improvements effectively.
      `,
    },
  ];

  const packages = [
    {
      icon: FaUsers,
      name: 'Small Business Package',
      price: '€3,800',
      description: 'Ideal for SMEs with up to 50 employees',
      features: [
        'DPIA Consulting (Basic)',
        'Data Protection Training (Online)',
        'Basic Subscription',
      ],
      details: `
### What You Get:
- **DPIA Consulting (Basic):** Assessment of one data processing activity to identify and mitigate privacy risks.
- **Online Data Protection Training:** Equip your team with essential GDPR knowledge through self-paced, certified online modules.
- **Basic Subscription:** Access foundational compliance tools, quarterly check-ins, and exclusive resources to maintain compliance.

### Benefits:
- **Solid Foundation:** Establish a solid foundation in data protection tailored for small businesses.
- **Educated Team:** Ensure your team is educated and aware of GDPR requirements.
- **Ongoing Support:** Receive ongoing support to sustain compliance without overextending your budget.
      `,
    },
    {
      icon: FaBuilding,
      name: 'Medium Business Package',
      price: '€12,500',
      description: 'Ideal for businesses with 50-200 employees',
      features: [
        'DPIA Consulting (Standard)',
        'Privacy Programme Development (Standard)',
        'Data Protection Training (On-Site)',
        'Standard Subscription',
      ],
      details: `
### What You Get:
- **DPIA Consulting (Standard):** Conduct assessments for up to three data processing activities, ensuring comprehensive risk management.
- **Privacy Programme Development (Standard):** Develop a customised privacy programme with implementation support to align with your business needs.
- **On-Site Data Protection Training:** Engage your team with interactive, on-site training sessions tailored to your organisational structure.
- **Standard Subscription:** Benefit from enhanced compliance support, including monthly check-ins, priority support, and audit discounts.

### Benefits:
- **Robust Privacy Framework:** Build a robust privacy framework that scales with your growing business.
- **Proactive Training:** Foster a culture of data protection through interactive and personalised training.
- **Comprehensive Support:** Enjoy comprehensive support and resources to maintain and improve compliance continuously.
      `,
    },
    {
      icon: FaChartLine,
      name: 'Enterprise Package',
      price: '€30,000+',
      description: 'Ideal for large corporations with over 200 employees',
      features: [
        'DPIA Consulting (Premium)',
        'Privacy Programme Development (Premium)',
        'Compliance Audit (Enterprise)',
        'Privacy by Design Consulting (Complex Project)',
        'Premium Subscription',
      ],
      details: `
### What You Get:
- **DPIA Consulting (Premium):** Unlimited assessments of your data processing activities, providing extensive risk management and compliance assurance.
- **Privacy Programme Development (Premium):** Full-scale development of a comprehensive privacy programme with year-long support for seamless integration.
- **Compliance Audit (Enterprise):** Conduct thorough audits tailored for large organisations, identifying and addressing complex compliance gaps.
- **Privacy by Design Consulting (Complex Project):** Integrate privacy principles into multiple applications or systems, ensuring comprehensive data protection from the ground up.
- **Premium Subscription:** Access the highest level of compliance support, including bi-weekly consultations, a dedicated account manager, comprehensive audits, and advanced reporting tools.

### Benefits:
- **Extensive Compliance:** Achieve top-tier compliance with extensive and integrated data protection services.
- **Expert Guidance:** Manage complex data environments with expert guidance and support.
- **Continuous Oversight:** Maintain continuous, high-level compliance oversight to protect your large-scale operations.
      `,
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {/* Modal for Drill-Downs */}
      <Modal
        isVisible={!!modalContent}
        onClose={closeModal}
        title={modalContent?.title || ''}
      >
        <ReactMarkdown className="prose max-w-none">
          {modalContent?.details || ''}
        </ReactMarkdown>
      </Modal>

      {/* Hero Section */}
      <section className="relative text-white">
        <div className="gradient-bg py-24 md:py-32">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-6">
            Comprehensive GDPR Compliance Solutions
            </h1>
            <p className="text-lg sm:text-xl lg:text-2xl mb-12 max-w-3xl mx-auto">
              Tailored pricing for organisations of all sizes
            </p>
          </div>
        </div>
      </section>

      {/* 2. Free Tools Section */}
      <section className="py-20 bg-gray-100">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold mb-12 text-center">Free GDPR Self-Assessment Tools</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {freeTools.map((tool) => (
              <FreeToolCard
                key={tool.title}
                {...tool}
                onClick={() =>
                  openModal({
                    title: tool.title,
                    details: tool.details,
                  })
                }
              />
            ))}
          </div>
        </div>
      </section>

      {/* 3. Subscription Plans */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold mb-12 text-center">Subscription Plans</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {subscriptions.map((sub, index) => (
              <PricingCard
                key={index}
                name={sub.name}
                price={sub.price}
                features={sub.features}
                link="/contact"
                isSubscription
                onClick={() =>
                  openModal({
                    title: sub.name,
                    details: sub.details,
                  })
                }
              />
            ))}
          </div>
        </div>
      </section>

      {/* 4. Package Deals */}
      <section className="py-20 bg-gray-100">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold mb-12 text-center">Package Deals</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {packages.map((pkg, index) => (
              <PricingCard
                key={index}
                name={pkg.name}
                price={pkg.price}
                features={pkg.features}
                link="/contact"
                isSubscription={false}
                icon={pkg.icon}
                onClick={() =>
                  openModal({
                    title: pkg.name,
                    details: pkg.details,
                  })
                }
              />
            ))}
          </div>
        </div>
      </section>

      {/* 5. Expert Consulting Services */}
      <section className="py-20">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold mb-12 text-center">Expert Consulting Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
            {expertServices.map((service) => (
              <ExpertServiceCard
                key={service.name}
                {...service}
                onClick={() =>
                  openModal({
                    title: service.name,
                    details: service.details,
                  })
                }
              />
            ))}
          </div>
        </div>
      </section>

      {/* 6. Additional Information */}
      <section className="py-20 bg-gray-100">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold mb-12 text-center">Additional Information</h2>

          {/* Value Proposition */}
          <div>
            <h3 className="text-2xl font-semibold mb-4">Our Value Proposition</h3>
            <p className="mb-4">Dedalus.ie's pricing is structured to provide maximum value through:</p>
            <ul className="list-disc pl-5">
              <li>Scalability: Flexible packages that grow with your business.</li>
              <li>Customisation: Tailored solutions to meet specific business needs.</li>
              <li>Expertise: Access to top-tier GDPR compliance experts and resources.</li>
              <li>Support: Ongoing support to ensure continuous compliance and peace of mind.</li>
            </ul>
          </div>
        </div>
      </section>

      {/* 8. Final Call to Action */}
      <section className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-20">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Get Started?</h2>
          <p className="text-xl mb-8">
            Contact us today for a personalised quote or to discuss how we can help your business achieve and maintain GDPR compliance.
          </p>
          <Link
            to="/contact"
            className="bg-white text-blue-600 font-semibold py-3 px-6 rounded-full hover:bg-gray-100 transition duration-300"
          >
            Contact Us Now
          </Link>
        </div>
      </section>
    </motion.div>
  );
};

export default PricingPage;
