import React from 'react';

const RSSFeedItem = ({ item }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 mb-4 hover:shadow-lg transition-shadow duration-300 flex">
      {item.imageUrl && (
        <img src={item.imageUrl} alt={item.title} className="w-24 h-24 object-cover mr-4 rounded" />
      )}
      <div>
        <h3 className="text-xl font-semibold mb-2 text-blue-600 hover:text-blue-800">
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            {item.title}
          </a>
        </h3>
        <p className="text-gray-600 mb-2">{new Date(item.pubDate).toLocaleDateString()}</p>
        <p className="text-gray-800">{item.contentSnippet}</p>
        <p className="mt-2 text-sm text-gray-500">Source: {item.creator || 'Unknown'}</p>
      </div>
    </div>
  );
};

export default RSSFeedItem;