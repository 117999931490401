import React, { createContext, useState, useEffect, useCallback, useMemo } from 'react';

export const RSSFeedContext = createContext();

const RSS_FEEDS = [
  "https://krebsonsecurity.com/feed/",
  "https://feeds.feedburner.com/TheHackersNews",
  "https://threatpost.com/feed/",
  "https://www.darkreading.com/rss.xml",
  "https://nakedsecurity.sophos.com/feed/",
  "https://www.schneier.com/feed/atom/"
];

export const RSSFeedProvider = ({ children }) => {
  const [rssItems, setRssItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [allItemsLoaded, setAllItemsLoaded] = useState(false);

  const rssFeeds = useMemo(() => RSS_FEEDS, []);

  const fetchRSSItems = useCallback(async (itemsPerFeed = 2) => {
    const allItems = [];

    try {
      for (const feed of rssFeeds) {
        try {
          const response = await fetch(`https://api.allorigins.win/get?url=${encodeURIComponent(feed)}`);
          const data = await response.json();
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(data.contents, "text/xml");
          const items = xmlDoc.querySelectorAll("item");

          for (let i = 0; i < items.length && i < itemsPerFeed; i++) {
            const item = items[i];
            const title = item.querySelector("title")?.textContent || "";
            const contentSnippet = item.querySelector("description")?.textContent?.slice(0, 150) + "..." || "";

            allItems.push({
              title,
              link: item.querySelector("link")?.textContent || "",
              pubDate: item.querySelector("pubDate")?.textContent || "",
              creator: item.querySelector("dc\\:creator")?.textContent || "Unknown",
              contentSnippet
            });
          }
        } catch (feedError) {
          console.error(`Error fetching feed ${feed}:`, feedError);
          // Continue with the next feed
        }
      }

      // Sort all items by date, most recent first
      allItems.sort((a, b) => new Date(b.pubDate) - new Date(a.pubDate));

      return allItems;
    } catch (err) {
      console.error("Error fetching RSS feeds:", err);
      throw new Error("Failed to fetch RSS feeds. Please try again later.");
    }
  }, [rssFeeds]);

  const loadInitialItems = useCallback(async () => {
    try {
      const initialItems = await fetchRSSItems(2);
      setRssItems(initialItems);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [fetchRSSItems]);

  const loadRemainingItems = useCallback(async () => {
    if (allItemsLoaded) return;

    try {
      setLoading(true);
      const allItems = await fetchRSSItems(5);
      setRssItems(allItems.slice(0, 50)); // Keep top 50 most recent items
      setAllItemsLoaded(true);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [fetchRSSItems, allItemsLoaded]);

  useEffect(() => {
    loadInitialItems();
  }, [loadInitialItems]);

  return (
    <RSSFeedContext.Provider value={{ rssItems, loading, error, allItemsLoaded, loadRemainingItems }}>
      {children}
    </RSSFeedContext.Provider>
  );
};
