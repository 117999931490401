import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './AuthContext';
import ErrorBoundary from './components/ErrorBoundary';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Tools from './pages/Tools';
import About from './pages/About';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import DPIAScreening from './pages/DPIAScreening';
import BlogPost from './pages/BlogPost';
import GDPRComplianceFactSheet from './pages/GDPRComplianceFactSheet';
import DataProtectionBestPractices from './pages/DataProtectionBestPractices';
import InternationalDataTransfersExplained from './pages/InternationalDataTransfersExplained';
import KnowledgeHub from './pages/KnowledgeHub';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import ProfilePage from './pages/ProfilePage';
import ProtectedRoute from './components/ProtectedRoute';
import CookieBanner from './components/CookieBanner';
import AIChecklist from './pages/AIChecklist';
import LegitimateInterestAssessment from './pages/LegitimateInterestAssessment';
import News from './pages/News';
import CookiesNotice from './pages/CookiesNotice';
import { RSSFeedProvider } from './RSSFeedContext';

function App() {
  useEffect(() => {
    console.log("App component mounted");
    console.log("localStorage cookieConsent:", localStorage.getItem('cookieConsent'));
  }, []);

  return (
    <ErrorBoundary>
      <AuthProvider>
        <RSSFeedProvider>
          <Router>
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/tools" element={<Tools />} />
              <Route path="/about" element={<About />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/blog" exact element={<Blog />} />
              <Route path="/blog/:id" element={<BlogPost />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/dpia-screening" element={<ProtectedRoute><DPIAScreening /></ProtectedRoute>} />
              <Route path="/gdpr-compliance-fact-sheet" element={<GDPRComplianceFactSheet />} />
              <Route path="/data-protection-best-practices" element={<DataProtectionBestPractices />} />
              <Route path="/International-data-transfers-explained" element={<InternationalDataTransfersExplained />} />
              <Route path="/knowledge-hub" element={<ProtectedRoute><KnowledgeHub /></ProtectedRoute>} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
              <Route path="/ai-compliance-checker" element={<ProtectedRoute><AIChecklist /></ProtectedRoute>} />
              <Route path="/legitimate-interest-assessment" element={<ProtectedRoute><LegitimateInterestAssessment /></ProtectedRoute>} />
              <Route path="/dpia-assessment" element={<ProtectedRoute><Tools /></ProtectedRoute>} />
              <Route path="/news" element={<News />} />
              <Route path="/cookies-notice" element={<CookiesNotice />} />
            </Routes>
            <Footer />
            <CookieBanner />
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
          </Router>
        </RSSFeedProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;