import React from 'react';

const NewsArticle = ({ title, date, summary, imageUrl }) => {
  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden mb-6">
      {imageUrl && (
        <img src={imageUrl} alt={title} className="w-full h-48 object-cover" />
      )}
      <div className="p-4">
        <h2 className="text-xl font-semibold mb-2">{title}</h2>
        <p className="text-gray-600 text-sm mb-2">{date}</p>
        <p className="text-gray-700">{summary}</p>
      </div>
    </div>
  );
};

export default NewsArticle;