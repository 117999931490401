import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Download, Search } from 'lucide-react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import FAQSection from '../components/FAQ';

const KnowledgeHub = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // Predefined list of DP resources
  const dpResources = [
    {
      id: '1',
      title: 'GDPR Compliance Checklist',
      description: 'A comprehensive checklist to ensure your organization is GDPR compliant.',
      pdfUrl: '/pdfs/gdpr-compliance-checklist.pdf',
    },
    {
      id: '2',
      title: 'Data Protection Impact Assessment Guide',
      description: 'Learn how to conduct a DPIA to identify and minimize data protection risks.',
      pdfUrl: '/pdfs/dpia-guide.pdf',
    },
    {
      id: '3',
      title: 'Privacy by Design Framework',
      description: 'Implement privacy measures into your projects from the start with this framework.',
      pdfUrl: '/pdfs/privacy-by-design.pdf',
    },
    {
      id: '4',
      title: 'Data Breach Response Plan',
      description: 'A step-by-step guide to effectively respond to and manage data breaches.',
      pdfUrl: '/pdfs/data-breach-response.pdf',
    },
    {
      id: '5',
      title: 'Employee Data Protection Training Manual',
      description: 'Educate your staff on data protection best practices with this comprehensive manual.',
      pdfUrl: '/pdfs/employee-dp-training.pdf',
    },
  ];

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'blogPosts'));
        const posts = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setBlogPosts(posts);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };

    fetchBlogPosts();
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredDpResources = dpResources.filter(resource =>
    resource.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    resource.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredBlogPosts = blogPosts.filter(post =>
    post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    post.excerpt.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {/* Hero Section */}
      <section className="relative text-white">
        <div className="gradient-bg py-24 md:py-32">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-6">
              Knowledge Hub
            </h1>
            <p className="text-lg sm:text-xl lg:text-2xl mb-12 max-w-3xl mx-auto">
              Explore our comprehensive resources on data protection and GDPR compliance
            </p>
            {/* Search Bar */}
            <div className="flex justify-center">
              <div className="relative w-full md:w-1/2">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearch}
                  placeholder="Search Data Protection resources..."
                  className="w-full p-4 pr-12 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800"
                />
                <Search className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="py-24 bg-gray-100">
        <div className="container mx-auto px-6">
          {/* DP Resources Section */}
          <div className="mb-16">
            <h2 className="text-3xl font-semibold mb-8">Data Protection Resources</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
              {filteredDpResources.map((resource) => (
                <div key={resource.id} className="bg-white p-8 rounded-lg shadow-md">
                  <h3 className="text-xl font-semibold mb-4">{resource.title}</h3>
                  <p className="text-gray-600 mb-6">{resource.description}</p>
                  <a
                    href={resource.pdfUrl}
                    download
                    className="inline-flex items-center justify-center text-green-600 hover:text-green-800 font-medium text-sm transition duration-200 ease-in-out"
                  >
                    <Download className="w-5 h-5 mr-2" /> Download PDF
                  </a>
                </div>
              ))}
            </div>
          </div>

          {/* Blog Posts Section */}
          <div className="mb-16">
            <h2 className="text-3xl font-semibold mb-8">Latest Blog Posts</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
              {filteredBlogPosts.map((post) => (
                <div key={post.id} className="bg-white p-8 rounded-lg shadow-md">
                  <a href={post.pdfUrl} target="_blank" rel="noopener noreferrer">
                    <h2 className="text-2xl font-semibold mb-4">{post.title}</h2>
                  </a>
                  <p className="text-gray-600 mb-4">{post.date}</p>
                  <p className="mb-6">{post.excerpt}</p>
                  <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 mb-4">
                    <a
                      href={post.pdfUrl}
                      download
                      className="inline-flex items-center justify-center text-green-600 hover:text-green-800 font-medium text-sm transition duration-200 ease-in-out"
                    >
                      <Download className="w-5 h-5 mr-2" /> Download PDF
                    </a>
                  </div>
                  <Link
                    to={post.route}
                    state={{ pdfUrl: post.pdfUrl }}
                    className="inline-block text-blue-600 hover:text-blue-800 font-medium text-sm transition duration-200 ease-in-out"
                  >
                    Read More →
                  </Link>
                </div>
              ))}
            </div>
          </div>

          {/* FAQ Section */}
          <FAQSection />
        </div>
      </section>

      <style jsx>{`
        .gradient-bg {
          background: linear-gradient(135deg, #6ab7ff 0%, #1e3c72 100%);
        }
      `}</style>
    </motion.div>
  );
};

export default KnowledgeHub;
