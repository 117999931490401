import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCdOYW25dRWYbg98OMx9lZBUSGvKh_FPoU",
  authDomain: "dedalus-3049e.firebaseapp.com",
  projectId: "dedalus-3049e",
  storageBucket: "dedalus-3049e.appspot.com",
  messagingSenderId: "417170235216",
  appId: "1:417170235216:web:b87c62ba85c4d313f95655",
  measurementId: "G-S6WB1CQF2M"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);

export default app;