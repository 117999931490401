import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {/* Hero Section */}
      <section className="relative text-white">
        <div className="gradient-bg py-24 md:py-32">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-6">
              Privacy Notice
            </h1>
            <p className="text-lg sm:text-xl lg:text-2xl mb-12 max-w-3xl mx-auto">
              Your privacy is important to us. Learn how we protect and manage your personal data.
            </p>
          </div>
        </div>
      </section>

           {/* Main Content */}
      <section className="py-24 bg-white">
        <div className="container mx-auto px-6">
          <div className="space-y-6 text-gray-800">
            {/* Introduction */}
            <h2 className="text-2xl font-semibold mt-8 mb-4 text-blue-600">Privacy Statement</h2>
            <p>
              Welcome to dedalus.ie. At Dedalus, we are committed to protecting your privacy and ensuring that your personal data is handled responsibly and in compliance with applicable data protection laws, including the General Data Protection Regulation (GDPR). This Privacy Notice outlines how we collect, use, disclose, and safeguard your personal data when you interact with our website and services. If you have any questions about this notice or wish to make a complaint regarding how we handle your personal data, please contact our Data Protection Officer (DPO) at:
            </p>
            <p className="mt-2">
              <strong>Data Protection Officer</strong><br />
              Dedalus.ie<br />
              123 Innovation Drive<br />
              Dublin 2<br />
              Email: <a href="mailto:dpo@dedalus.ie" className="text-blue-600">dpo@dedalus.ie</a>
            </p>

            {/* Information We Collect and How We Use It */}
            <h2 className="text-2xl font-semibold mt-8 mb-4 text-blue-600">Information We Collect and How We Use It</h2>

            {/* Clients */}
            <h3 className="text-xl font-semibold mt-6 mb-3 text-blue-600">Clients</h3>
            <p>
              When you engage with us as a client, we collect and process your personal data to provide and improve our data protection and GDPR compliance services, including DPIA consulting, privacy programme development, and compliance audits. This includes information necessary for delivering these services, managing our professional relationships, collecting fees, and communicating relevant updates or invitations to events. Additionally, we retain client data for a standard period of ten years to comply with legal and regulatory obligations unless a longer retention period is required.
            </p>

            {/* Business Contacts */}
            <h3 className="text-xl font-semibold mt-6 mb-3 text-blue-600">Business Contacts</h3>
            <p>
              For our business operations, we collect personal data such as names, email addresses, job titles, telephone numbers, areas of business, job roles, jurisdictions, languages, and seniority levels of business contacts. This information facilitates internal communications, supports analytics for business intelligence, enhances marketing efforts, and helps us understand how our clients and prospective clients use our services. Personal data processed for these purposes is retained only as long as necessary. If a business contact opts out of receiving marketing communications, their details will still be retained to maintain essential business interactions, though marketing materials will cease.
            </p>

            {/* Suppliers */}
            <h3 className="text-xl font-semibold mt-6 mb-3 text-blue-600">Suppliers</h3>
            <p>
              We gather personal data from our suppliers to manage and review the services they provide. This includes contact information necessary for negotiating payment arrangements and overseeing service delivery. Our legal basis for processing this data is the performance of supplier contracts. Supplier data is retained for seven years following the end of the business relationship unless legal or regulatory obligations require longer retention.
            </p>

            {/* Website Visitors */}
            <h3 className="text-xl font-semibold mt-6 mb-3 text-blue-600">Website Visitors</h3>
            <p>
              When you visit our website, we automatically collect certain information such as your IP address, browsing behaviour, device details, and interactions with our site. This data helps us enhance your user experience and improve our website offerings. If you register for events or subscribe to our marketing communications through the website, we collect personal data to manage these registrations and tailor our communications to your preferences. We use cookies to store small amounts of data that facilitate a personalised and efficient browsing experience. For more details on our cookie usage, please refer to the "Cookies Policy" section below.
            </p>

            {/* Experts and Consultants */}
            <h3 className="text-xl font-semibold mt-6 mb-3 text-blue-600">Experts and Consultants</h3>
            <p>
              We collect personal data, including names, email addresses, telephone numbers, and other business contact information from consultants, experts, and other third parties to provide services to clients. This data is essential for managing relationships with these professionals who assist us in delivering services. We process this information to evaluate their suitability, manage contractual relationships, and facilitate effective service delivery. Expert data is retained for the duration of their engagement or up to three years for professional appointments.
            </p>

            {/* Recruitment */}
            <h3 className="text-xl font-semibold mt-6 mb-3 text-blue-600">Recruitment</h3>
            <p>
              During our recruitment processes, we collect personal data from candidates to assess their suitability for various roles within Dedalus. This includes information submitted through third-party recruitment platforms and direct applications. We retain unsuccessful applications for eighteen months and successful applications within individual HR files indefinitely. For trainee recruitment, applications are retained for fourteen months if screened out at the application stage and two years if screened out at the interview stage.
            </p>

            {/* Employees */}
            <h3 className="text-xl font-semibold mt-6 mb-3 text-blue-600">Employees</h3>
            <p>
              Personal data pertaining to our employees is managed through various internal systems and applications. We provide employees with a detailed privacy notice outlining the purposes for which their data is processed and their rights regarding that data. The processing of employee data is based on the necessity to perform employment contracts and manage internal operations. For additional information, employees can contact our Human Resources Department.
            </p>

            {/* How We Protect Your Data */}
            <h2 className="text-2xl font-semibold mt-8 mb-4 text-blue-600">How We Protect Your Data</h2>
            <p>
              We employ industry-standard security measures to protect your personal information from unauthorised access, loss, or misuse. All Dedalus personnel are bound by strict confidentiality agreements to ensure your data is handled responsibly. While we strive to protect your data, no security system is entirely secure. If you have concerns about the security of your data, please contact us at <a href="mailto:infosec@dedalus.ie" className="text-blue-600">infosec@dedalus.ie</a>.
            </p>

            {/* Transfers of Personal Data to Non-EEA Countries */}
            <h2 className="text-2xl font-semibold mt-8 mb-4 text-blue-600">Transfers of Personal Data to Non-EEA Countries</h2>
            <p>
              Your personal data may be transferred to countries outside the European Economic Area (EEA) that are recognised as providing an adequate level of data protection. In cases where data transfers occur to countries without such recognition, we ensure that appropriate safeguards are in place, such as Standard Contractual Clauses (SCCs) or Binding Corporate Rules (BCRs), to protect your privacy rights.
            </p>

            {/* Your Rights in Relation to Your Personal Data */}
            <h2 className="text-2xl font-semibold mt-8 mb-4 text-blue-600">Your Rights in Relation to Your Personal Data</h2>
            <p>
              Under the GDPR, you have several rights regarding your personal data:
            </p>
            <ul className="list-disc pl-6 mt-2">
              <li><strong>Access:</strong> You can request access to the personal data we hold about you and obtain details about its processing.</li>
              <li><strong>Rectification:</strong> You may request that inaccurate or incomplete data be corrected.</li>
              <li><strong>Erasure:</strong> Under certain conditions, you can request the deletion of your personal data.</li>
              <li><strong>Restriction of Processing:</strong> You have the right to restrict the processing of your personal data in specific circumstances.</li>
              <li><strong>Objection:</strong> You have the right to object to the processing of your personal data on legitimate grounds.</li>
              <li><strong>Data Portability:</strong> You can request to receive your personal data in a structured, commonly used, and machine-readable format or require us to transmit that data to another controller.</li>
              <li><strong>Rights Related to Automated Decision-Making:</strong> You have the right not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning you or similarly significantly affects you.</li>
            </ul>
            <p>
              If you wish to exercise any of these rights (excluding the withdrawal of consent for marketing communications), please contact our DPO at <a href="mailto:dpo@dedalus.ie" className="text-blue-600">dpo@dedalus.ie</a>. If you are dissatisfied with our handling of your data, you may lodge a complaint with the Irish Data Protection Commission.
            </p>

            {/* Consent */}
            <h2 className="text-2xl font-semibold mt-8 mb-4 text-blue-600">Consent</h2>
            <p>
              Where we process your personal data based on your consent, you have the right to withdraw that consent at any time. You can withdraw your consent by contacting us at <a href="mailto:dpo@dedalus.ie" className="text-blue-600">dpo@dedalus.ie</a>. Please note that withdrawing consent does not affect the lawfulness of processing conducted before the withdrawal.
            </p>

            {/* Cookies Policy */}
            <h2 className="text-2xl font-semibold mt-8 mb-4 text-blue-600">Cookies Policy</h2>
            <p>
              Dedalus uses cookies to enhance your browsing experience on our website. Cookies are small files stored on your device that help us remember your preferences and provide tailored content. We use essential cookies necessary for the website's functionality and performance, and with your consent, we use analytics cookies to understand user behaviour and improve our services.
            </p>
            <p>
              <strong>Cookie Consent:</strong> Before placing non-essential cookies on your device, we will obtain your explicit consent through our cookie consent tool. You can manage your cookie preferences at any time through this tool or your browser settings. For more details on our cookie usage, please refer to our <Link to="/cookies-notice" className="text-blue-600">Cookies Notice</Link>.
            </p>

            {/* Changes to Our Privacy Notice */}
            <h2 className="text-2xl font-semibold mt-8 mb-4 text-blue-600">Changes to Our Privacy Notice</h2>
            <p>
              We may update this Privacy Notice periodically to reflect changes in our data processing practices or legal requirements. Any updates will be posted on this page, and the revised notice will apply immediately upon publication. We encourage you to review this Privacy Notice regularly to stay informed about how we protect your personal data.
            </p>

            {/* Links to Third-Party Websites */}
            <h2 className="text-2xl font-semibold mt-8 mb-4 text-blue-600">Links to Third-Party Websites</h2>
            <p>
              Our website may contain links to external websites operated by third parties. Dedalus.ie is not responsible for the privacy practices or the content of these external sites. We encourage you to review the privacy policies of any third-party websites you visit to understand how your personal data is handled.
            </p>

            {/* Effective Date */}
            <h2 className="text-2xl font-semibold mt-8 mb-4 text-blue-600">Effective Date</h2>
            <p>
              This Privacy Notice is effective as of April 2024.
            </p>
          </div>
        </div>
      </section>

 {/* Download Button and Note */}
 <div className="bg-white py-8">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <a
            href="/pdfs/privacy_notice.txt"
            download
            className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 mb-4"
          >
            Download Privacy Notice
          </a>
          <p className="text-red-600 font-semibold mt-2">
                     </p>
        </div>
      </div>

      <style jsx>{`
        .gradient-bg {
          background: linear-gradient(135deg, #6ab7ff 0%, #1e3c72 100%);
        }
        a {
          text-decoration: underline;
        }
      `}</style>
    </motion.div>
  );
};

export default PrivacyPolicy;
