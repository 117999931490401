import React from 'react';
import { motion } from 'framer-motion';

const FAQSection = () => {
  console.log('FAQSection component is rendering');

  const faqData = [
    {
      question: 'What is Data Protection?',
      answer: 'Data Protection refers to the practices and technologies used to safeguard personal and sensitive information from unauthorized access, use, disclosure, disruption, modification, or destruction.'
    },
    {
      question: 'Why is Data Protection important?',
      answer: 'Data Protection is crucial for maintaining privacy, complying with legal regulations, and building trust with users by ensuring their personal information is handled securely.'
    },
    {
      question: 'What are the key principles of Data Protection?',
      answer: 'The key principles include lawfulness, fairness, transparency, purpose limitation, data minimization, accuracy, storage limitation, integrity, and confidentiality.'
    },
    {
      question: 'How can I ensure compliance with Data Protection laws?',
      answer: 'Ensure compliance by understanding relevant laws, implementing appropriate security measures, conducting regular audits, training staff, and maintaining clear data handling policies.'
    },
    {
      question: 'What rights do individuals have under Data Protection regulations?',
      answer: 'Individuals have rights such as the right to access their data, rectify inaccuracies, erase data, restrict processing, data portability, and object to data processing under regulations like GDPR.'
    },
  ];

  return (
    <motion.section
      className="py-24 bg-white"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
        <div className="space-y-6">
          {faqData.map((faq, index) => (
            <div key={index} className="border-b pb-4">
              <h3 className="text-xl font-semibold">{faq.question}</h3>
              <p className="text-gray-700 mt-2">{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default FAQSection;