import React from 'react';

const blogPosts = [
  {
    id: 1,
    title: 'GDPR Compliance Fact Sheet',
    excerpt:
      'Learn the basics of GDPR compliance and how it affects your business operations.',
    date: 'October 10, 2024',
    content: `
      <h1>Privacy Law Factsheet – Ireland and the United Kingdom</h1>
      <p>This factsheet provides comprehensive information about privacy laws in Ireland and the United Kingdom (UK), outlining your obligations under these laws and offering best practice recommendations. It reflects the most current legal landscape up to October 2023.</p>
      <h2>Does Data Protection Law Apply to You?</h2>
      <p>In both Ireland and the UK, data protection is governed by the <strong>General Data Protection Regulation (GDPR)</strong> and the <strong>Data Protection Act 2018</strong>. For Ireland, the GDPR applies directly as it is an EU regulation, and the Data Protection Act 2018 supplements it. In the UK, following its exit from the EU, the GDPR has been incorporated into UK law as the <strong>UK GDPR</strong>, alongside the Data Protection Act 2018.</p>
      <p>These laws apply to any organization that processes personal data of individuals residing in the European Economic Area (EEA) for Ireland or the UK for the UK GDPR, regardless of the organization's size or annual turnover. There is no minimum threshold; even small businesses and sole traders must comply if they handle personal data.</p>
      <h3>Applicability Criteria:</h3>
      <ul>
        <li><strong>Establishment Criterion</strong>: If your organization has an establishment in the EEA (for Ireland) or the UK, and processes personal data in the context of activities of that establishment.</li>
        <li><strong>Targeting Criterion</strong>: If your organization is outside the EEA or UK but offers goods or services to, or monitors the behavior of, individuals in the EEA or UK.</li>
      </ul>
      <p><strong>Key Takeaway</strong>: If you process personal data of individuals in Ireland or the UK, data protection laws likely apply to you.</p>
      <h2>Why Do You Need a Privacy Policy?</h2>
      <p>Transparency is a core principle under the GDPR and UK GDPR. Organizations are required to inform individuals about how their personal data is collected, used, stored, disclosed, and destroyed. This is typically achieved through a <strong>Privacy Policy</strong> or <strong>Privacy Notice</strong>.</p>
      <h3>Legal Requirements:</h3>
      <ul>
        <li><strong>Articles 13 and 14 of the GDPR</strong> mandate that you provide specific information to data subjects when collecting their personal data, whether directly or indirectly.</li>
        <li><strong>Information to Include</strong>:
          <ul>
            <li><strong>Identity and contact details</strong> of the data controller (and, where applicable, the data protection officer).</li>
            <li><strong>Purpose of the data processing</strong> and the legal basis.</li>
            <li><strong>Legitimate interests pursued</strong>, if processing is based on this ground.</li>
            <li><strong>Recipients or categories of recipients</strong> of the personal data.</li>
            <li>Details of transfers to third countries and safeguards in place.</li>
            <li><strong>Retention period</strong> or criteria used to determine it.</li>
            <li><strong>Rights of the data subject</strong>, including access, rectification, erasure, restriction, objection, and data portability.</li>
            <li><strong>The right to withdraw consent at any time</strong>, if processing is based on consent.</li>
            <li><strong>The right to lodge a complaint with a supervisory authority</strong>.</li>
            <li>Whether providing personal data is a statutory or contractual requirement, and the consequences of failing to provide it.</li>
            <li><strong>The existence of automated decision-making</strong>, including profiling, and meaningful information about the logic involved.</li>
          </ul>
        </li>
      </ul>
      <h3>Consequences of Non-Compliance:</h3>
      <p>Failure to have a compliant Privacy Policy can lead to significant fines:</p>
      <ul>
        <li><strong>For less severe infringements</strong>: Up to €10 million, or 2% of the annual global turnover of the preceding financial year, whichever is higher.</li>
        <li><strong>For more severe infringements</strong>: Up to €20 million, or 4% of the annual global turnover, whichever is higher.</li>
      </ul>
      <p><strong>Best Practice Recommendation</strong>: Having a clear and accessible Privacy Policy not only ensures legal compliance but also builds trust with your customers and stakeholders.</p>
      <h2>What Do You Need to Do with Your Privacy Policy?</h2>
      <p>Your Privacy Policy should be:</p>
      <ul>
        <li><strong>Easily Accessible</strong>: Make it readily available on your website, app, or any platform where you collect personal data. A common practice is to include a direct link in the footer of each webpage or within app settings.</li>
        <li><strong>Written in Clear Language</strong>: Use plain and concise language that is easily understood by your audience. Avoid legal jargon, especially if your services are directed at children or vulnerable individuals.</li>
        <li><strong>Up-to-Date</strong>: Regularly review and update your Privacy Policy to reflect any changes in your data processing activities, organizational structure, or legal obligations.</li>
      </ul>
      <h3>Action Steps:</h3>
      <ul>
        <li><strong>Audit Your Data Practices</strong>: Understand what personal data you collect, how you use it, where you store it, and with whom you share it.</li>
        <li><strong>Draft or Revise Your Privacy Policy</strong>: Ensure it covers all required elements as per Articles 13 and 14 of the GDPR.</li>
        <li><strong>Communicate Changes</strong>: Notify individuals of significant changes to your Privacy Policy, especially if the changes affect how their personal data is handled.</li>
      </ul>
      <h2>What Is Personal Data?</h2>
      <p><strong>Personal data</strong> refers to any information relating to an identified or identifiable natural person, known as the <strong>data subject</strong>. An identifiable person is one who can be identified, directly or indirectly, by reference to:</p>
      <ul>
        <li>An identifier such as a name, identification number, location data, or online identifier.</li>
        <li>Factors specific to the physical, physiological, genetic, mental, economic, cultural, or social identity of that person.</li>
      </ul>
      <h3>Examples Include:</h3>
      <ul>
        <li>Names and addresses.</li>
        <li>Email addresses and phone numbers.</li>
        <li>IP addresses and cookie identifiers.</li>
        <li>Financial details.</li>
        <li>Health records.</li>
        <li>Employee records.</li>
      </ul>
      <p><strong>Reasonably Identifiable Individuals</strong>: Even if the information does not directly identify someone, if it can be combined with other data to identify an individual, it is considered personal data.</p>
      <p><strong>Special Note on Employee Data</strong>: Employee personal data is subject to the same protections under the GDPR. Organizations must handle employee data in compliance with data protection principles and provide appropriate notices.</p>
      <h2>What Is Special Category Data?</h2>
      <p><strong>Special category data</strong> is a subset of personal data that is more sensitive and requires additional protection. This includes data revealing:</p>
      <ul>
        <li>Racial or ethnic origin.</li>
        <li>Political opinions.</li>
        <li>Religious or philosophical beliefs.</li>
        <li>Trade union membership.</li>
        <li>Genetic data.</li>
        <li>Biometric data used for identification purposes.</li>
        <li>Health information.</li>
        <li>Data concerning a person's sex life or sexual orientation.</li>
      </ul>
      <h3>Conditions for Processing:</h3>
      <p>Processing special category data is prohibited unless one of the specific conditions in Article 9 of the GDPR is met, such as:</p>
      <ul>
        <li><strong>Explicit Consent</strong>: The data subject has given explicit consent for processing.</li>
        <li><strong>Employment, Social Security, and Social Protection Law</strong>: Necessary for carrying out obligations in these areas.</li>
        <li><strong>Vital Interests</strong>: To protect the vital interests of the data subject or another person.</li>
        <li><strong>Public Interest</strong>: Processing is necessary for reasons of substantial public interest, based on law.</li>
        <li><strong>Legal Claims</strong>: For the establishment, exercise, or defense of legal claims.</li>
      </ul>
      <p><strong>Additional Safeguards</strong>: When processing special category data, you must implement appropriate policies and safeguards to protect this sensitive information.</p>
      <h2>Why Is a Privacy Notice Required?</h2>
      <p>A <strong>Privacy Notice</strong> ensures transparency and informs individuals about how their personal data is collected and used. Under Articles 13 and 14 of the GDPR, you are required to provide this information:</p>
      <ul>
        <li><strong>At the time of data collection</strong> if obtained directly from the data subject.</li>
        <li><strong>Within a reasonable period</strong> (not exceeding one month) if obtained indirectly.</li>
      </ul>
      <h3>Situations Requiring a Privacy Notice:</h3>
      <ul>
        <li>When a user signs up on your website or app.</li>
        <li>When collecting data through forms, surveys, or competitions.</li>
        <li>When obtaining personal data from third-party sources.</li>
        <li>When deploying cookies or similar tracking technologies.</li>
      </ul>
      <h3>Content of the Privacy Notice:</h3>
      <ul>
        <li>All information specified in the "Why Do You Need a Privacy Policy?" section.</li>
        <li>Any additional details relevant to the specific data collection context.</li>
      </ul>
      <p><strong>Best Practice Recommendation</strong>: Ensure that the Privacy Notice is presented in a manner that draws the individual's attention, such as a layered approach with key information upfront and more detailed information available through links.</p>
      <h2>Best Practices for GDPR Compliance</h2>
      <ul>
        <li>Conduct regular data protection impact assessments (DPIAs).</li>
        <li>Ensure transparency in data processing activities.</li>
        <li>Implement data minimization principles.</li>
        <li>Provide individuals with control over their personal data.</li>
        <li>Implement robust security measures to protect personal data.</li>
      </ul>
      <p>By following these guidelines, you can ensure that your data processing activities comply with GDPR and protect the privacy of individuals.</p>
    `,
  }
];

const GDPRComplianceFactSheet = () => {
  const post = blogPosts[0];
  
  return (
    <div className="container mx-auto py-12 px-6">
      <h1 className="text-4xl font-bold mb-8">{post.title}</h1>
      <p className="text-sm text-gray-600 mb-4">Published on: {post.date}</p>
      <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: post.content }} />
    </div>
  );
};

export default GDPRComplianceFactSheet;