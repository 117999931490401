// src/pages/TermsOfService.js
import React from 'react';
import { motion } from 'framer-motion';

const TermsOfService = () => {
  return (
    <motion.section
      className="py-24 bg-white"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto px-6">
        <h1 className="text-4xl font-bold mb-12">Terms of Service</h1>
        <p className="mb-6">
          These terms and conditions outline the rules and regulations for the use of Dedalus.ie’s website and services.
        </p>
        {/* Add your detailed terms of service here */}
      </div>
    </motion.section>
  );
};

export default TermsOfService;
