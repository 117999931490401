import React from 'react';

const Alert = ({ message, type }) => {
    return (
        <div className={`alert alert-${type}`}>  
            {message}
        </div>
    );
};

const AlertDescription = ({ children }) => {
    return <div className="alert-description">{children}</div>;
};

export { Alert, AlertDescription };
