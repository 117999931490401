import React from 'react';

const CookiesNotice = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Cookies Notice</h1>
      
      <p className="mb-4">
        This Cookies Notice explains how we use cookies and similar technologies on our website. 
        By using our website, you consent to the use of cookies as described in this notice.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">What are cookies?</h2>
      <p className="mb-4">
        Cookies are small text files that are placed on your device when you visit a website. 
        They are widely used to make websites work more efficiently and provide information to the website owners.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">How we use cookies</h2>
      <p className="mb-4">
        We use different types of cookies for various purposes:
      </p>

      <h3 className="text-xl font-semibold mt-4 mb-2">1. Essential Cookies</h3>
      <p className="mb-4">
        These cookies are necessary for the website to function properly. They enable core functionality such as security, network management, and accessibility. You may disable these by changing your browser settings, but this may affect how the website functions.
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>session_id: Maintains user session (Duration: Session)</li>
        <li>csrf_token: Prevents cross-site request forgery (Duration: Session)</li>
      </ul>

      <h3 className="text-xl font-semibold mt-4 mb-2">2. Functional Cookies</h3>
      <p className="mb-4">
        These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third-party providers whose services we have added to our pages.
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>language_pref: Remembers user's language preference (Duration: 1 year)</li>
        <li>theme_choice: Stores user's theme preference (Duration: 1 year)</li>
      </ul>

      <h3 className="text-xl font-semibold mt-4 mb-2">3. Analytical Cookies</h3>
      <p className="mb-4">
        These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site.
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>_ga: Used to distinguish users (Duration: 2 years)</li>
        <li>_gid: Used to distinguish users (Duration: 24 hours)</li>
      </ul>

      <h3 className="text-xl font-semibold mt-4 mb-2">4. Marketing Cookies</h3>
      <p className="mb-4">
        These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites.
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>ad_id: Used to identify users for targeted advertising (Duration: 30 days)</li>
        <li>campaign_click: Tracks marketing campaign effectiveness (Duration: 90 days)</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Managing Cookies</h2>
      <p className="mb-4">
        You can manage your cookie preferences at any time by clicking the 'Cookie Settings' button at the bottom right of the page. You can choose to accept or reject non-essential cookies.
      </p>
      <p className="mb-4">
        Most web browsers allow some control of most cookies through the browser settings. To find out more about cookies, including how to see what cookies have been set, visit <a href="https://www.aboutcookies.org" className="text-blue-500 underline">www.aboutcookies.org</a> or <a href="https://www.allaboutcookies.org" className="text-blue-500 underline">www.allaboutcookies.org</a>.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Changes to this Cookies Notice</h2>
      <p className="mb-4">
        We may update this Cookies Notice from time to time. We encourage you to periodically review this page for the latest information on our cookie practices.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Contact Us</h2>
      <p className="mb-4">
        If you have any questions about our cookie practices or this Cookies Notice, please contact our Data Protection Team at <a href="mailto:privacy@dedalus.ie" className="text-blue-500 underline">privacy@example.com</a>.
      </p>
    </div>
  );
};

export default CookiesNotice;