import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import NewsArticle from '../components/NewsArticle';
import RSSFeedItem from '../components/RSSFeedItem';
import { RSSFeedContext } from '../RSSFeedContext';

const News = () => {
  const { rssItems, loading, error } = useContext(RSSFeedContext);

  const newsArticles = [
    {
      title: "GDPR Compliance: 5 Years Later",
      date: "2023-05-25",
      summary: "Five years after the implementation of GDPR, we look at its impact on data protection practices worldwide.",
      imageUrl: "https://example.com/gdpr-image.jpg"
    },
    {
      title: "AI and Data Protection: Navigating the Challenges",
      date: "2023-05-20",
      summary: "As AI technologies advance, new data protection concerns arise. We explore the intersection of AI and data privacy.",
      imageUrl: "https://example.com/ai-privacy-image.jpg"
    },
    {
      title: "Data Breaches in 2023: Trends and Lessons",
      date: "2023-05-15",
      summary: "An analysis of major data breaches in 2023 and what organizations can learn to improve their data security.",
      imageUrl: "https://example.com/data-breach-image.jpg"
    }
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {/* Hero Section */}
      <section className="relative text-white">
        <div className="gradient-bg py-24 md:py-32">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-6">
              Data Protection News
            </h1>
            <p className="text-lg sm:text-xl lg:text-2xl mb-12 max-w-3xl mx-auto">
              Stay informed with the latest updates in data protection and privacy
            </p>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="py-24 bg-gray-100">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-semibold mb-8">Featured Articles</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-16">
            {newsArticles.map((article, index) => (
              <NewsArticle key={index} {...article} />
            ))}
          </div>

          <h2 className="text-3xl font-semibold mb-8">Latest from RSS Feeds</h2>
          {loading ? (
            <p className="text-xl text-gray-600">Loading RSS feeds...</p>
          ) : error ? (
            <p className="text-xl text-red-500">{error}</p>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {rssItems.map((item, index) => (
                <RSSFeedItem key={index} item={item} />
              ))}
            </div>
          )}
        </div>
      </section>

      <style jsx>{`
        .gradient-bg {
          background: linear-gradient(135deg, #6ab7ff 0%, #1e3c72 100%);
        }
      `}</style>
    </motion.div>
  );
};

export default News;
