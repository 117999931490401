import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useAuth } from '../AuthContext';
import { auth } from '../firebase';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const menuItems = [
    'Home',
    'Tools',
    'Pricing',
    'Knowledge Hub',
    'News',
    'About',
    'Contact',
  ];

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <motion.header
      className={`fixed w-full z-50 transition-all duration-300 ${
        scrolled ? 'bg-white shadow-md' : 'bg-transparent'
      }`}
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <nav className="container mx-auto px-6 py-4">
        <div className="flex items-center justify-between">
          <Link
            to="/"
            className={`text-2xl font-bold ${
              scrolled ? 'text-blue-600' : 'text-white'
            }`}
          >
            Dedalus.ie
          </Link>
          <div className="hidden md:flex space-x-6 items-center">
            {menuItems.map((item) => (
              <Link
                key={item}
                to={item === 'Home' ? '/' : `/${item.toLowerCase().replace(' ', '-')}`}
                className={`${
                  scrolled
                    ? 'text-gray-800 hover:text-blue-600'
                    : 'text-white hover:text-blue-200'
                } transition-colors duration-300`}
              >
                {item}
              </Link>
            ))}
            {user ? (
              <>
                <Link
                  to="/profile"
                  className={`${
                    scrolled
                      ? 'text-gray-800 hover:text-blue-600'
                      : 'text-white hover:text-blue-200'
                  } transition-colors duration-300`}
                >
                  Profile
                </Link>
                <button
                  onClick={handleLogout}
                  className={`${
                    scrolled
                      ? 'bg-blue-600 text-white'
                      : 'bg-white text-blue-600'
                  } px-4 py-2 rounded-md hover:opacity-80 transition-opacity duration-300`}
                >
                  Logout
                </button>
              </>
            ) : (
              <Link
                to="/login"
                className={`${
                  scrolled
                    ? 'bg-blue-600 text-white'
                    : 'bg-white text-blue-600'
                } px-4 py-2 rounded-md hover:opacity-80 transition-opacity duration-300`}
              >
                Login
              </Link>
            )}
          </div>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className={`md:hidden focus:outline-none ${
              scrolled ? 'text-gray-800' : 'text-white'
            }`}
            aria-label="Toggle Menu"
          >
            <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
              <path
                d={
                  isOpen
                    ? 'M6 18L18 6M6 6l12 12'
                    : 'M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z'
                }
              />
            </svg>
          </button>
        </div>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="md:hidden mt-4 py-2 bg-white rounded shadow-lg"
          >
            {menuItems.map((item) => (
              <Link
                key={item}
                to={item === 'Home' ? '/' : `/${item.toLowerCase().replace(' ', '-')}`}
                className="block px-4 py-2 text-gray-800 hover:bg-blue-50 hover:text-blue-600 transition-colors duration-300"
              >
                {item}
              </Link>
            ))}
            {user ? (
              <>
                <Link
                  to="/profile"
                  className="block px-4 py-2 text-gray-800 hover:bg-blue-50 hover:text-blue-600 transition-colors duration-300"
                >
                  Profile
                </Link>
                <button
                  onClick={handleLogout}
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-blue-50 hover:text-blue-600 transition-colors duration-300"
                >
                  Logout
                </button>
              </>
            ) : (
              <Link
                to="/login"
                className="block px-4 py-2 text-gray-800 hover:bg-blue-50 hover:text-blue-600 transition-colors duration-300"
              >
                Login
              </Link>
            )}
          </motion.div>
        )}
      </nav>
    </motion.header>
  );
};

export default Header;
