import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../firebase';
import { signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword } from 'firebase/auth';
import { useAuth } from '../AuthContext';
import { toast } from 'react-toastify';

function LoginPage() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const signInWithGoogle = () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(() => {
        toast.success('Signed in successfully!');
        navigate('/');
      })
      .catch((error) => {
        console.error(error);
        toast.error('Failed to sign in. Please try again.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const signInWithEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        toast.success('Signed in successfully!');
        navigate('/');
      })
      .catch((error) => {
        console.error(error);
        toast.error('Failed to sign in. Please check your email and password.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const signOut = () => {
    setLoading(true);
    auth.signOut()
      .then(() => {
        toast.success('Signed out successfully');
        navigate('/');
      })
      .catch((error) => {
        console.error(error);
        toast.error('Failed to sign out. Please try again.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-4xl flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="w-full md:w-1/2 p-8 bg-blue-600">
          <h1 className="text-4xl font-bold text-white mb-4">Dedalus</h1>
          <p className="text-white text-lg mb-6">Expert Data Protection Consultancy</p>
          <div className="text-white mb-8">
            <h2 className="text-2xl font-semibold mb-4">Our Services:</h2>
            <ul className="list-disc list-inside">
              <li>GDPR Compliance Training</li>
              <li>Data Protection Impact Assessments</li>
              <li>Privacy by Design</li>
              <li>Data Protection Specialist as a Service</li>
              <li>Privacy Programme Consulting</li>
            </ul>
          </div>
          <p className="text-white">
            Learn more at <a href="https://www.dedalus.ie" className="underline hover:text-blue-200">dedalus.ie</a>
          </p>
        </div>
        <div className="w-full md:w-1/2 p-8">
          {user ? (
            <div>
              <h1 className="text-2xl font-bold mb-4 text-gray-800">Welcome, {user.displayName || user.email}!</h1>
              <p className="mb-4 text-gray-600">Email: {user.email}</p>
              {user.photoURL && <img src={user.photoURL} alt="Profile" className="w-24 h-24 rounded-full mx-auto mb-4" />}
              <button 
                onClick={signOut} 
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full transition duration-300"
                disabled={loading}
              >
                Sign Out
              </button>
            </div>
          ) : (
            <div>
              <h2 className="text-2xl font-bold mb-6 text-gray-800">Sign In</h2>
              <form onSubmit={signInWithEmail} className="mb-6">
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                    Email
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-6">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                    Password
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="password"
                    type="password"
                    placeholder="******************"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="flex items-center justify-between mb-6">
                  <button
                    className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full transition duration-300"
                    type="submit"
                    disabled={loading}
                  >
                    Sign In
                  </button>
                </div>
              </form>
              <div className="text-center mb-6">
                <Link to="/register" className="inline-block align-baseline font-bold text-sm text-blue-600 hover:text-blue-800">
                  Register
                </Link>
              </div>
              <div className="mt-6">
                <p className="text-gray-600 text-sm mb-4 text-center">Or</p>
                <button 
                  onClick={signInWithGoogle}
                  className="flex items-center justify-center w-full px-4 py-2 border border-gray-300 text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-300"
                  disabled={loading}
                >
                  <svg className="h-5 w-5 mr-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
                    <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
                    <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
                    <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
                  </svg>
                  Sign in with Google
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginPage;