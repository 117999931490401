import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import {
  FaShieldAlt,
  FaClipboardCheck,
  FaBalanceScale,
  FaSearch,
  FaCheckCircle,
} from 'react-icons/fa';

const Tools = () => {
  const selfAssessmentTools = [
    {
      icon: FaShieldAlt,
      title: 'AI Compliance Checker',
      description: 'Ensure your AI applications are fully compliant with GDPR regulations.',
      details: 'Our AI Compliance Checker provides an intuitive interface to assess your AI systems for data protection risks. It helps you identify potential compliance issues in your AI applications, focusing on key areas such as data minimization, purpose limitation, and algorithmic transparency.',
      benefits: [
        'Identify GDPR risks specific to AI applications',
        'Receive actionable recommendations for compliance',
        'Track your AI compliance progress over time',
        'Generate compliance reports for stakeholders',
      ],
      link: '/ai-compliance-checker',
    },
    {
      icon: FaClipboardCheck,
      title: 'DPIA Screening Assessment',
      description: 'Determine if you need to conduct a full Data Protection Impact Assessment.',
      details: 'This tool guides you through a series of questions to evaluate whether your data processing activities require a comprehensive DPIA. It helps you save time and resources by focusing on the projects that truly need in-depth assessment, based on the criteria set out in Article 35 of the GDPR.',
      benefits: [
        'Quickly identify high-risk processing activities',
        'Save time and resources on unnecessary full DPIAs',
        'Ensure compliance with GDPR Article 35 requirements',
        'Document your screening process for accountability',
      ],
      link: '/dpia-screening',
    },
    {
      icon: FaBalanceScale,
      title: 'Legitimate Interest Assessment',
      description: 'Evaluate your lawful basis for data processing.',
      details: 'Our Legitimate Interest Assessment tool helps you determine when it\'s appropriate to rely on legitimate interests for data processing. It guides you through a structured assessment, helping you balance your interests against the rights of data subjects, as required by Article 6(1)(f) of the GDPR.',
      benefits: [
        'Systematically assess your legitimate interests',
        'Balance your interests against data subject rights',
        'Document your decision-making process',
        'Demonstrate compliance with GDPR Article 6(1)(f)',
      ],
      link: '/legitimate-interest-assessment',
    },
    {
      icon: FaSearch,
      title: 'DPIA Assessment',
      description: 'Conduct a comprehensive Data Protection Impact Assessment.',
      details: 'For projects that require a full DPIA, our assessment tool provides a step-by-step guide to identify and minimize data protection risks. It helps you thoroughly document your assessment, demonstrating compliance with GDPR requirements and following the guidance provided by the Article 29 Working Party.',
      benefits: [
        'Systematically assess data protection risks',
        'Identify appropriate measures to address risks',
        'Generate comprehensive DPIA reports',
        'Demonstrate compliance with GDPR Article 35',
      ],
      link: '/dpia-assessment',
    },
  ];

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* Hero Section */}
      <section className="relative text-white">
        <div className="gradient-bg py-24 md:py-32">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-6">
              Comprehensive GDPR Compliance Tools
            </h1>
            <p className="text-lg sm:text-xl lg:text-2xl mb-12 max-w-3xl mx-auto">
              Empower your business with our suite of self-assessment tools for GDPR compliance.
            </p>
          </div>
        </div>
      </section>

      {/* Tools Section */}
      <section className="py-24 bg-gradient-to-b from-white to-gray-100">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl sm:text-4xl font-bold text-center mb-16 text-gray-800">
            Self-Assessment Tools
          </h2>
          <p className="text-center text-lg sm:text-xl mb-16 max-w-3xl mx-auto text-gray-600">
            Our suite of self-assessment tools empowers you to take control of your GDPR compliance journey. These tools provide a structured approach to evaluating your data protection practices and identifying areas for improvement.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-12">
            {selfAssessmentTools.map((tool, index) => (
              <motion.div
                key={tool.title}
                className="bg-white p-6 sm:p-8 rounded-lg shadow-lg text-center transition-all duration-300 hover:shadow-xl"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <tool.icon className="text-4xl sm:text-5xl text-blue-600 mb-6 mx-auto" />
                <h3 className="text-xl sm:text-2xl font-semibold mb-4 text-gray-800">
                  {tool.title}
                </h3>
                <p className="mb-6 text-gray-600">{tool.description}</p>
                <p className="mb-6 text-sm text-gray-500">{tool.details}</p>
                <h4 className="font-semibold mb-4 text-gray-700">Key Benefits:</h4>
                <ul className="list-none mb-6">
                  {tool.benefits.map((benefit, index) => (
                    <li key={index} className="flex items-start mb-2">
                      <FaCheckCircle className="text-green-500 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-gray-600 text-left">{benefit}</span>
                    </li>
                  ))}
                </ul>
                <Link
                  to={tool.link}
                  className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-300"
                >
                  Start Assessment
                </Link>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <style jsx>{`
        .gradient-bg {
          background: linear-gradient(135deg, #6ab7ff 0%, #1e3c72 100%);
        }
      `}</style>
    </motion.main>
  );
};

export default Tools;