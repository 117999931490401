// src/Home.js

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  FaShieldAlt,
  FaClipboardCheck,
  FaBalanceScale,
  FaExternalLinkAlt,
  FaLock,
  FaDollarSign,
  FaLaptopCode,
  FaBolt,
  FaHeartbeat,
  FaIndustry,
  FaRobot,
} from 'react-icons/fa';
import { useAuth } from '../AuthContext';
import ReelBanner from '../components/ReelBanner';

const Home = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/login');
  };

  const services = [
    {
      icon: FaShieldAlt,
      title: 'AI Compliance Checker',
      description:
        'Ensure your AI applications comply with data protection regulations.',
      link: 'https://ai-checklist.vercel.app/',
      buttonText: 'Start Assessment',
      external: true,
    },
    {
      icon: FaClipboardCheck,
      title: 'DPIA Screening Assessment',
      description:
        'Evaluate the need for a Data Protection Impact Assessment.',
      link: '/dpia-screening',
      buttonText: 'Start Assessment',
    },
    {
      icon: FaBalanceScale,
      title: 'Legitimate Interest Tool',
      description:
        'Determine the lawful basis for your data processing activities.',
      link: 'https://legitimate-interest-assessment.vercel.app/',
      buttonText: 'Start Assessment',
      external: true,
    },
  ];

  const industries = [
    {
      icon: FaDollarSign,
      name: 'Financial Services',
      description:
        'Tailored compliance solutions for banks, insurance companies, and financial institutions.',
    },
    {
      icon: FaLaptopCode,
      name: 'Technology',
      description:
        'Ensuring data protection for software developers, SaaS providers, and tech startups.',
    },
    {
      icon: FaBolt,
      name: 'Energy',
      description:
        'Compliance strategies for energy providers, utilities, and renewable energy firms.',
    },
    {
      icon: FaHeartbeat,
      name: 'Healthcare',
      description:
        'Protecting patient data for hospitals, clinics, and medical research organisations.',
    },
    {
      icon: FaIndustry,
      name: 'Manufacturing',
      description:
        'Data protection solutions for manufacturing plants, supply chains, and industrial operations.',
    },
    {
      icon: FaRobot,
      name: 'Emerging Technologies',
      description:
        'Expertise in data protection for AI, IoT, blockchain, and other cutting-edge technologies.',
    },
  ];

  return (
    <motion.main
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={{
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        exit: { opacity: 0 },
      }}
      transition={{ duration: 0.5 }}
      className="overflow-hidden"
    >
      {/* Hero Section */}
      <section className="relative text-white">
        <div className="gradient-bg py-16 sm:py-24 md:py-32 lg:py-48">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <motion.h1
              className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4 sm:mb-6"
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1 }}
            >
              GDPR Compliance Simplified
            </motion.h1>
            <motion.p
              className="text-base sm:text-lg md:text-xl lg:text-2xl mb-8 sm:mb-12 max-w-3xl mx-auto"
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1, delay: 0.5 }}
            >
              Empowering businesses with free, easy-to-use data protection tools.
            </motion.p>
            <motion.div
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}
            >
              <button
                onClick={handleGetStarted}
                className="btn-primary text-base sm:text-lg px-6 sm:px-8 py-2 sm:py-3 rounded-full transition-all duration-300 transform hover:scale-105"
              >
                Get Started
              </button>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-16 sm:py-24 bg-gradient-to-b from-white to-gray-100">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-center mb-8 sm:mb-16 text-gray-800">
            Dedalus App Suite
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 lg:gap-12">
            {services.map((service, index) => (
              <motion.div
                key={service.title}
                className="bg-white p-4 sm:p-6 md:p-8 rounded-lg shadow-lg text-center transition-all duration-300 hover:shadow-xl"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <service.icon className="text-3xl sm:text-4xl md:text-5xl text-blue-600 mb-4 sm:mb-6 mx-auto" />
                <h3 className="text-lg sm:text-xl md:text-2xl font-semibold mb-2 sm:mb-4 text-gray-800">
                  {service.title}
                </h3>
                <p className="mb-4 sm:mb-6 text-gray-600 text-sm sm:text-base">{service.description}</p>
                {user ? (
                  service.external ? (
                    <a
                      href={service.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center px-4 sm:px-6 py-2 sm:py-3 border border-transparent text-sm sm:text-base font-medium rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-300"
                    >
                      {service.buttonText}
                      <FaExternalLinkAlt className="ml-2 -mr-1 h-4 w-4" />
                    </a>
                  ) : (
                    <Link
                      to={service.link}
                      className="inline-flex items-center px-4 sm:px-6 py-2 sm:py-3 border border-transparent text-sm sm:text-base font-medium rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-300"
                    >
                      {service.buttonText}
                    </Link>
                  )
                ) : (
                  <div className="text-gray-500 text-sm sm:text-base">
                    <FaLock className="inline-block mr-2" />
                    <Link to="/login" className="text-blue-600 hover:text-blue-800 font-semibold">
                      Log in
                    </Link>
                    {" or "}
                    <Link to="/register" className="text-blue-600 hover:text-blue-800 font-semibold">
                      Register
                    </Link>
                    {" to access"}
                  </div>
                )}
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Industries and Sectors Section */}
      <section className="py-16 sm:py-24 industries-bg text-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6 sm:mb-12 text-center">Industries We Specialise In</h2>
          <p className="text-center text-base sm:text-lg md:text-xl mb-8 sm:mb-16 max-w-3xl mx-auto">
            Tailored GDPR compliance solutions for a diverse range of industries, including emerging technologies.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 lg:gap-12">
            {industries.map((industry, index) => (
              <motion.div
                key={index}
                className="bg-white bg-opacity-10 p-4 sm:p-6 rounded-lg shadow-md text-center backdrop-filter backdrop-blur-lg transition-all duration-300 hover:bg-opacity-20"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <industry.icon className="text-3xl sm:text-4xl md:text-5xl text-blue-200 mb-3 sm:mb-4 mx-auto" />
                <h3 className="text-lg sm:text-xl md:text-2xl font-semibold mb-2 sm:mb-3">{industry.name}</h3>
                <p className="text-blue-100 text-sm sm:text-base">{industry.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* About Section */}
      <section className="py-16 sm:py-24 bg-gradient-to-b from-gray-100 to-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col lg:flex-row items-center">
            <motion.div
              className="lg:w-1/2 mb-8 lg:mb-0 lg:pr-8 xl:pr-12"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 sm:mb-6 text-gray-800">About Us</h2>
              <p className="text-base sm:text-lg mb-6 sm:mb-8 text-gray-600 leading-relaxed">
                Dedalus combines regulatory consulting with legaltech innovation to simplify GDPR compliance for businesses. We provide free and subscription-based tools that make data protection accessible to organisations of all sizes.
              </p>
              <Link
                to="/about"
                className="bg-blue-600 text-white font-semibold py-2 sm:py-3 px-6 sm:px-8 rounded-full hover:bg-blue-700 transition duration-300 inline-block text-sm sm:text-base"
              >
                Learn More
              </Link>
            </motion.div>
            <motion.div
              className="lg:w-1/2"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <div className="bg-white w-full max-w-lg h-64 sm:h-80 rounded-lg shadow-xl flex items-center justify-center overflow-hidden mx-auto">
                <img src="/images/Getty1.png" alt="Team" className="w-full h-full object-cover rounded-lg" />
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Reel Banner Section */}
      <section className="py-6 sm:py-8 bg-gray-100">
        <ReelBanner />
      </section>
      
      <style jsx>{`
        .gradient-bg {
          background: linear-gradient(135deg, #6ab7ff 0%, #1e3c72 100%);
        }
        .industries-bg {
          background: linear-gradient(135deg, #4a90e2 0%, #1e3c72 100%);
        }
        .btn-primary {
          background-color: #3498db;
          color: white;
          transition: all 0.3s ease;
        }
        .btn-primary:hover {
          background-color: #2980b9;
          transform: translateY(-2px);
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }
      `}</style>
    </motion.main>
  );
};

export default Home;
