import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { RSSFeedContext } from '../RSSFeedContext';
import { Alert, AlertDescription } from './ui/alert';
import { Loader2 } from 'lucide-react';

const ReelBanner = () => {
  const { rssItems = [], loading, error } = useContext(RSSFeedContext);
  const [position, setPosition] = useState(0);
  const contentRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);

  const calculateContentWidth = useCallback(() => {
    if (contentRef.current) {
      return contentRef.current.scrollWidth;
    }
    return 0;
  }, []);

  useEffect(() => {
    if (rssItems.length === 0 || isPaused) return;

    const contentWidth = calculateContentWidth();
    const animate = () => {
      setPosition((prevPosition) => {
        const newPosition = prevPosition - 1;
        return newPosition <= -contentWidth ? 0 : newPosition;
      });
    };

    const interval = setInterval(animate, 50);

    return () => clearInterval(interval);
  }, [rssItems, isPaused, calculateContentWidth]);

  const handleMouseEnter = () => setIsPaused(true);
  const handleMouseLeave = () => setIsPaused(false);

  const truncateText = (text, maxLength) => {
    if (!text || text.length <= maxLength) return text || ""; // Return empty string if text is undefined or empty
    return text.slice(0, maxLength) + '...';
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center">
        <h2 className="text-lg font-semibold mb-2">Data Protection News</h2>
        <div className="flex justify-center items-center h-20">
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          Loading...
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center">
        <h2 className="text-lg font-semibold mb-2">Data Protection News</h2>
        <Alert variant="destructive">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      </div>
    );
  }

  if (!rssItems || rssItems.length === 0) {
    return (
      <div className="flex flex-col items-center">
        <h2 className="text-lg font-semibold mb-2">Data Protection News</h2>
        <Alert>
          <AlertDescription>There are no RSS items to display.</AlertDescription>
        </Alert>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center w-full">
      <h2 className="text-lg font-semibold mb-2">Data Protection News</h2>
      <div
        className="reel-banner bg-white p-2 rounded-lg shadow-md overflow-hidden w-full"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          ref={contentRef}
          className="reel-content relative whitespace-nowrap"
          style={{ transform: `translateX(${position}px)`, transition: 'transform 0.1s linear' }}
        >
          {rssItems.concat(rssItems).map((item, index) => (
            <a
              key={`${item.link}-${index}`}
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-4 py-2 text-blue-600 hover:text-blue-800 hover:underline transition-colors duration-200 border-r border-gray-300 last:border-r-0"
            >
              <div className="font-semibold">{truncateText(item.title, 50)}</div>
              <div className="text-sm text-gray-600">{truncateText(item.contentSnippet, 100)}</div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReelBanner;
