import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useAuth } from '../AuthContext';

function CustomerProfilePage() {
  const { user, updateProfile } = useAuth();
  const [activeTab, setActiveTab] = useState('overview');
  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState({
    displayName: '',
    companyName: '',
    jobTitle: '',
    industry: '',
    phoneNumber: '',
    preferredContactMethod: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    if (user) {
      setEditedUser({
        displayName: user.displayName || '',
        companyName: user.companyName || '',
        jobTitle: user.jobTitle || '',
        industry: user.industry || '',
        phoneNumber: user.phoneNumber || '',
        preferredContactMethod: user.preferredContactMethod || 'email',
      });
    }
  }, [user]);

  if (!user) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedUser(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      await updateProfile(editedUser);
      setSuccess('Profile updated successfully!');
      setIsEditing(false);
    } catch (error) {
      setError('Error updating profile: ' + error.message);
    }
  };

  const tabContent = {
    overview: (
      <div>
        <h3 className="text-xl font-semibold mb-4">Company Information</h3>
        {isEditing ? (
          <>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">Company Name</label>
              <input
                type="text"
                name="companyName"
                value={editedUser.companyName}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">Industry</label>
              <input
                type="text"
                name="industry"
                value={editedUser.industry}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
              />
            </div>
          </>
        ) : (
          <>
            <p><strong>Company:</strong> {editedUser.companyName}</p>
            <p><strong>Industry:</strong> {editedUser.industry}</p>
          </>
        )}
        <h3 className="text-xl font-semibold mb-4 mt-6">Contact Information</h3>
        <p><strong>Email:</strong> {user.email}</p>
        {isEditing ? (
          <>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">Phone Number</label>
              <input
                type="tel"
                name="phoneNumber"
                value={editedUser.phoneNumber}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">Preferred Contact Method</label>
              <select
                name="preferredContactMethod"
                value={editedUser.preferredContactMethod}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
              >
                <option value="email">Email</option>
                <option value="phone">Phone</option>
              </select>
            </div>
          </>
        ) : (
          <>
            <p><strong>Phone:</strong> {editedUser.phoneNumber || 'Not provided'}</p>
            <p><strong>Preferred Contact:</strong> {editedUser.preferredContactMethod === 'email' ? 'Email' : 'Phone'}</p>
          </>
        )}
      </div>
    ),
    tools: (
      <div>
        <h3 className="text-xl font-semibold mb-4">Your Data Protection Tools</h3>
        <ul className="space-y-2">
          <li className="flex items-center">
            <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">Active</span>
            <span>GDPR Compliance Assessment</span>
          </li>
          <li className="flex items-center">
            <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">Active</span>
            <span>Data Protection Impact Assessment (DPIA)</span>
          </li>
          <li className="flex items-center">
            <span className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">Upcoming</span>
            <span>Data Breach Response Planning</span>
          </li>
        </ul>
      </div>
    ),
    account: (
      <div>
        <h3 className="text-xl font-semibold mb-4">Account Information</h3>
        <p><strong>User ID:</strong> {user.uid}</p>
        <p><strong>Email Verified:</strong> {user.emailVerified ? 'Yes' : 'No'}</p>
        <p><strong>Account Created:</strong> {user.metadata.creationTime}</p>
        <p><strong>Last Sign In:</strong> {user.metadata.lastSignInTime}</p>
        <div className="mt-6">
          <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mr-2">
            Change Password
          </button>
          <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded">
            Delete Account
          </button>
        </div>
      </div>
    ),
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {/* Hero Section */}
      <section className="relative text-white">
        <div className="gradient-bg py-24 md:py-32">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-6">
              Your Profile
            </h1>
            <p className="text-lg sm:text-xl lg:text-2xl mb-12 max-w-3xl mx-auto">
              Manage your account and data protection tools
            </p>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="py-24 bg-gray-100">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          {/* Profile Information */}
          <div className="bg-white shadow-xl rounded-lg overflow-hidden">
            <div className="relative h-32 bg-blue-200">
              <div className="absolute bottom-0 left-10 -mb-16 border-4 border-white rounded-full w-32 h-32 shadow-lg bg-white flex items-center justify-center text-blue-500 text-4xl font-bold">
                {editedUser.companyName ? editedUser.companyName.charAt(0).toUpperCase() : '?'}
              </div>
            </div>
            <div className="pt-16 p-8">
              {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">{error}</div>}
              {success && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">{success}</div>}
              <div className="flex justify-between items-center">
                <div>
                  {isEditing ? (
                    <>
                      <input
                        type="text"
                        name="displayName"
                        value={editedUser.displayName}
                        onChange={handleInputChange}
                        className="text-3xl font-bold text-gray-800 border-b-2 border-blue-500 focus:outline-none"
                      />
                      <input
                        type="text"
                        name="jobTitle"
                        value={editedUser.jobTitle}
                        onChange={handleInputChange}
                        className="text-gray-600 border-b border-gray-300 focus:outline-none mt-1"
                      />
                    </>
                  ) : (
                    <>
                      <h2 className="text-3xl font-bold text-gray-800">{editedUser.displayName}</h2>
                      <p className="text-gray-600">{editedUser.jobTitle}</p>
                    </>
                  )}
                </div>
                {isEditing ? (
                  <button
                    onClick={handleSubmit}
                    className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:-translate-y-1"
                  >
                    Save Changes
                  </button>
                ) : (
                  <button
                    onClick={() => setIsEditing(true)}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:-translate-y-1"
                  >
                    Edit Profile
                  </button>
                )}
              </div>
              <div className="mt-8 flex border-b">
                {['Overview', 'Tools', 'Account'].map((tab) => (
                  <button
                    key={tab}
                    className={`px-4 py-2 font-medium ${
                      activeTab === tab.toLowerCase()
                        ? 'text-blue-500 border-b-2 border-blue-500'
                        : 'text-gray-500 hover:text-blue-500'
                    }`}
                    onClick={() => setActiveTab(tab.toLowerCase())}
                  >
                    {tab}
                  </button>
                ))}
              </div>
              <div className="mt-8">
                {tabContent[activeTab]}
              </div>
            </div>
          </div>
        </div>
      </section>

      <style jsx>{`
        .gradient-bg {
          background: linear-gradient(135deg, #6ab7ff 0%, #1e3c72 100%);
        }
      `}</style>
    </motion.div>
  );
}

export default CustomerProfilePage;