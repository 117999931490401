// CookieBanner.jsx

import React, { useState, useEffect, useCallback } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

const cookieDetails = {
  essential: {
    title: "Essential Cookies",
    description: "These cookies are necessary for the website to function and cannot be switched off in our systems.",
    required: true,
    cookies: [
      { name: "session_id", purpose: "Maintains user session", duration: "Session" },
      { name: "csrf_token", purpose: "Prevents cross-site request forgery", duration: "Session" },
    ]
  },
  functional: {
    title: "Functional Cookies",
    description: "These cookies enable the website to provide enhanced functionality and personalisation.",
    required: false,
    cookies: [
      { name: "language_pref", purpose: "Remembers user's language preference", duration: "1 year" },
      { name: "theme_choice", purpose: "Stores user's theme preference", duration: "1 year" },
    ]
  },
  analytical: {
    title: "Analytical Cookies",
    description: "These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site.",
    required: false,
    cookies: [
      { name: "_ga", purpose: "Used to distinguish users", duration: "2 years" },
      { name: "_gid", purpose: "Used to distinguish users", duration: "24 hours" },
    ]
  },
  marketing: {
    title: "Marketing Cookies",
    description: "These cookies may be set through our site by our advertising partners to build a profile of your interests.",
    required: false,
    cookies: [
      { name: "ad_id", purpose: "Used to identify users for targeted advertising", duration: "30 days" },
      { name: "campaign_click", purpose: "Tracks marketing campaign effectiveness", duration: "90 days" },
    ]
  }
};

const CookieBanner = ({ onAccept }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const [hasUserChosen, setHasUserChosen] = useState(false);
  const [preferences, setPreferences] = useState({
    essential: true,
    functional: false,
    analytical: false,
    marketing: false
  });
  const [activeTab, setActiveTab] = useState('preferences');
  const [expandedSections, setExpandedSections] = useState({
    essential: false,
    functional: false,
    analytical: false,
    marketing: false
  });

  const setCookie = (name, value, duration) => {
    let expires;
    if (duration === 'Session') {
      expires = undefined;
    } else {
      const durationMatch = duration.match(/^(\d+)\s*(\w+)$/);
      if (durationMatch) {
        const amount = parseInt(durationMatch[1], 10);
        const unit = durationMatch[2].toLowerCase();
        let multiplier = 1;

        switch (unit) {
          case 'day':
          case 'days':
            multiplier = 1;
            break;
          case 'hour':
          case 'hours':
            multiplier = 1 / 24;
            break;
          case 'month':
          case 'months':
            multiplier = 30;
            break;
          case 'year':
          case 'years':
            multiplier = 365;
            break;
          default:
            multiplier = 1;
        }

        expires = amount * multiplier;
      }
    }

    Cookies.set(name, value, { expires });
  };

  const deleteCookie = (name) => {
    Cookies.remove(name);
  };

  const applyPreferences = useCallback((prefs) => {
    Object.keys(cookieDetails).forEach((categoryKey) => {
      const category = cookieDetails[categoryKey];
      const isEnabled = prefs[categoryKey];

      category.cookies.forEach((cookie) => {
        if (isEnabled || category.required) {
          setCookie(cookie.name, '1', cookie.duration);
        } else {
          deleteCookie(cookie.name);
        }
      });
    });
  }, []);

  const savePreferences = useCallback((prefs) => {
    try {
      localStorage.setItem('cookieConsent', JSON.stringify(prefs));
      setPreferences(prefs);
      setIsVisible(false);
      setIsBannerVisible(false);
      setHasUserChosen(true);
      applyPreferences(prefs);
      console.log('Preferences saved:', prefs);
      onAccept(); // Call the onAccept prop when preferences are saved
    } catch (error) {
      console.error('Error saving to localStorage', error);
    }
  }, [applyPreferences, onAccept]);

  const handleAcceptAll = useCallback(() => {
    const allAccepted = {
      essential: true,
      functional: true,
      analytical: true,
      marketing: true
    };
    savePreferences(allAccepted);
    console.log('All cookies accepted');
  }, [savePreferences]);

  const handleRejectAll = useCallback(() => {
    const allRejected = {
      essential: true,
      functional: false,
      analytical: false,
      marketing: false
    };
    savePreferences(allRejected);
    console.log('All non-essential cookies rejected');
  }, [savePreferences]);

  const handlePreferenceChange = useCallback((key) => {
    setPreferences(prev => {
      const updatedPrefs = { ...prev, [key]: !prev[key] };
      console.log(`Preference for ${key} toggled to ${updatedPrefs[key]}`);
      return updatedPrefs;
    });
  }, []);

  const handleResetPreferences = useCallback(() => {
    const defaultPreferences = {
      essential: true,
      functional: false,
      analytical: false,
      marketing: false
    };
    savePreferences(defaultPreferences);
    console.log('Preferences reset to default');
  }, [savePreferences]);

  const toggleSection = useCallback((key) => {
    setExpandedSections(prev => {
      const updatedSections = { ...prev, [key]: !prev[key] };
      console.log(`Section ${key} toggled to ${updatedSections[key]}`);
      return updatedSections;
    });
  }, []);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    console.log('Consent from localStorage:', consent);
    if (!consent) {
      setIsBannerVisible(true);
    } else {
      try {
        const savedPreferences = JSON.parse(consent);
        console.log('Saved Preferences:', savedPreferences);
        setPreferences(savedPreferences);
        applyPreferences(savedPreferences);
        setIsBannerVisible(false);
        setHasUserChosen(true);
        onAccept(); // Call the onAccept prop when consent is already given
      } catch (error) {
        console.error('Error parsing cookieConsent from localStorage', error);
        setIsBannerVisible(true);
      }
    }
  }, [applyPreferences, onAccept]);

  const CookieWall = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded-md max-w-3xl w-11/12 max-h-screen overflow-y-auto shadow-lg">
        <h2 className="mb-4 text-2xl text-gray-800">Cookie Consent Required</h2>
        <p className="mb-6 text-gray-600">
          We use cookies to enhance your experience. Some are essential for the site to function, while others help us improve our services. Please choose your preferences below to continue using our website.
        </p>
        <div className="flex flex-col md:flex-row justify-center items-center gap-4">
          <button onClick={handleRejectAll} className="w-full md:w-auto px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition">
            Reject All
          </button>
          <button onClick={handleAcceptAll} className="w-full md:w-auto px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition">
            Accept All
          </button>
          <button onClick={() => { setIsVisible(true); setIsBannerVisible(false); }} className="w-full md:w-auto px-4 py-2 bg-gray-700 text-white rounded hover:bg-gray-800 transition">
            Manage Cookies
          </button>
        </div>
      </div>
    </div>
  );

  const ManagementInterface = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded-md max-w-3xl w-11/12 max-h-screen overflow-y-auto shadow-lg">
        <h2 className="mb-4 text-2xl text-gray-800">Cookie Consent Management</h2>
        <p className="mb-6 text-gray-600">
          We value your privacy. Please review and manage your cookie preferences below to enhance your browsing experience while maintaining control over your data.
        </p>
        <div className="flex mb-6 border-b border-gray-200">
          <button
            onClick={() => setActiveTab('preferences')}
            className={`flex-1 py-2 px-4 text-center rounded-t-md ${activeTab === 'preferences' ? 'bg-gray-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
            aria-selected={activeTab === 'preferences'}
            role="tab"
          >
            Preferences
          </button>
          <button
            onClick={() => setActiveTab('info')}
            className={`flex-1 py-2 px-4 text-center rounded-t-md ${activeTab === 'info' ? 'bg-gray-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
            aria-selected={activeTab === 'info'}
            role="tab"
          >
            More Info
          </button>
        </div>
        {activeTab === 'preferences' && (
          <div>
            {Object.keys(cookieDetails).map((key) => (
              <div key={key} className="mb-6 border-b border-gray-200 pb-4">
                <div className="flex justify-between items-center">
                  <button
                    onClick={() => toggleSection(key)}
                    className="flex items-center text-left text-gray-800 font-semibold focus:outline-none"
                    aria-expanded={expandedSections[key]}
                  >
                    <span className="mr-2">{cookieDetails[key].title}</span>
                    {cookieDetails[key].required && <span className="text-red-500">*</span>}
                    <span className="ml-2">{expandedSections[key] ? '▲' : '▼'}</span>
                  </button>
                  <label className="flex items-center text-sm text-gray-600">
                    <input
                      type="checkbox"
                      checked={preferences[key]}
                      onChange={() => handlePreferenceChange(key)}
                      disabled={cookieDetails[key].required}
                      className="mr-2"
                    />
                    {preferences[key] ? 'Enabled' : 'Disabled'}
                  </label>
                </div>
                <p className="text-sm text-gray-600 mt-2">
                  {cookieDetails[key].description}
                </p>
                {expandedSections[key] && (
                  <div className="mt-4 pl-4">
                    <p className="text-sm font-semibold">Cookies in this category:</p>
                    <ul className="list-disc list-inside text-sm text-gray-600 mt-2">
                      {cookieDetails[key].cookies.map((cookie, index) => (
                        <li key={index}>
                          <strong>{cookie.name}</strong>: {cookie.purpose} (Duration: {cookie.duration})
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        {activeTab === 'info' && (
          <div>
            <p className="mb-6 text-gray-600">
              Our website uses cookies to improve your browsing experience and help us understand how you interact with our services. You can learn more about the types of cookies we use and how to manage your preferences in our detailed <Link to="/cookies-notice" className="text-blue-500 underline">Cookies Notice</Link>.
            </p>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">Your Rights</h3>
            <p className="mb-6 text-gray-600">
              You have the right to accept or reject cookies. Essential cookies cannot be rejected as they are necessary for the website to function properly. You can change your cookie preferences at any time by clicking the 'Cookie Settings' button at the bottom right of the page.
            </p>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">Contact Us</h3>
            <p className="text-gray-600">
              If you have any questions about our cookie practices or this Cookie Consent Management tool, please contact our Data Protection Officer at <a href="mailto:privacy@example.com" className="text-blue-500 underline">privacy@example.com</a>.
            </p>
          </div>
        )}
        <div className="mt-6 flex justify-end space-x-2">
          <button
            onClick={handleResetPreferences}
            className="px-4 py-2 bg-gray-200 text-black rounded hover:bg-gray-300 transition"
          >
            Reset
          </button>
          <button
            onClick={handleRejectAll}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition"
          >
            Reject All
          </button>
          <button
            onClick={() => savePreferences(preferences)}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
          >
            Save Preferences
          </button>
          <button
            onClick={handleAcceptAll}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition"
          >
            Accept All
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isBannerVisible && <CookieWall />}
      {isVisible && <ManagementInterface />}
      {!isBannerVisible && !isVisible && (
        <button 
          className={`fixed bottom-4 right-4 bg-gray-700 text-white rounded-full p-3 shadow-lg hover:bg-gray-800 z-50 transition-all duration-300 ${
            hasUserChosen ? 'opacity-70 hover:opacity-100' : 'opacity-100'
          }`}
          onClick={() => setIsVisible(true)}
          aria-label="Cookie Settings"
          title="Cookie Settings"
        >
          {hasUserChosen ? '🍪' : '🍪 Cookie Settings'}
        </button>
      )}
    </>
  );
};

export default CookieBanner;
