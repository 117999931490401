// src/pages/BlogPost.js
import React from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';

const BlogPost = () => {
  const { id } = useParams();

  const blogPosts = {
    1: {
      title: 'Understanding GDPR Compliance',
      content:
        'GDPR compliance is essential for businesses operating within the EU or handling EU citizens’ data. In this article, we explore the fundamental principles of GDPR and provide actionable steps to ensure your organization is compliant.',
      date: 'October 10, 2024',
    },
    2: {
      title: 'Data Protection Best Practices',
      content:
        'Protecting personal data is crucial in today’s digital landscape. We discuss best practices for data protection, including encryption, access controls, and employee training.',
      date: 'November 5, 2024',
    },
    3: {
      title: 'International Data Transfers Explained',
      content:
        'Transferring data across borders presents unique challenges. Learn about the legal frameworks and mechanisms that facilitate compliant international data transfers.',
      date: 'December 15, 2024',
    },
  };

  const post = blogPosts[id];

  if (!post) {
    return (
      <motion.section
        className="py-24 bg-white text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="container mx-auto px-6">
          <h1 className="text-4xl font-bold mb-12">Post Not Found</h1>
          <p>The blog post you are looking for does not exist.</p>
        </div>
      </motion.section>
    );
  }

  return (
    <motion.section
      className="py-24 bg-white"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto px-6">
        <h1 className="text-4xl font-bold mb-4">{post.title}</h1>
        <p className="text-gray-600 mb-8">{post.date}</p>
        <p className="text-lg">{post.content}</p>
      </div>
    </motion.section>
  );
};

export default BlogPost;
